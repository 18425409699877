import {
	Button,
	Col,
	Form,
	Input,
	Radio,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import axios from "axios";
import {
	customerTypeOptions,
	quadrantTypeOptions,
} from "../../constants/Customer";
import { CustomSpinner } from "../utils/CustomComponents";
const { Option } = Select;

const OpportunityContact = ({ id, callback }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [contactType, setContactType] = useState("Email");
	const [approved, setApproved] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [status, setStatus] = useState("");
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getClient();
	}, []);

	const getClient = async () => {
		const url = api + "/opportunity/get/" + id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				const payload = data.payload;
				setInitialValues({
					userContactName: payload.userContactName || undefined,
					userContactPhone: payload.userContactPhone
						? payload.userContactPhone.substring(2)
						: undefined,
					userContactEmail: payload.userContactEmail || undefined,
					userContactSMS: payload.userContactSMS
						? payload.userContactSMS.substring(2)
						: undefined,
					companyContactType: payload.companyContactType || "Email",
					companyName: payload.companyName || undefined,
					companyCustomerType: payload.companyCustomerType || undefined,
					companyQuadrantType: payload.companyQuadrantType || undefined,
					companyAddress: payload.companyAddress || undefined,
					companyCity: payload.companyCity || undefined,
					companyState: payload.companyState || undefined,
					companyZip: payload.companyZip || undefined,
					companyAccountsPayableContact:
						payload.companyAccountsPayableContact || undefined,
					companyAccountsPayableEmail:
						payload.companyAccountsPayableEmail || undefined,
					companyAccountsPayablePhone: payload.companyAccountsPayablePhone
						? payload.companyAccountsPayablePhone.substring(2)
						: undefined,
				});

				setApproved(payload.approved);
				setStatus(payload.status);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get user");
			console.error(err.message);
			return null;
		}
	};

	const updateOpportunity = async (data) => {
		const opportunity = {
			...data,
		};

		if (data.userContactSMS)
			opportunity.userContactSMS = "+1" + data.userContactSMS;
		if (data.userContactPhone)
			opportunity.userContactPhone = "+1" + data.userContactPhone;
		if (data.companyAccountsPayablePhone)
			opportunity.companyAccountsPayablePhone =
				"+1" + data.companyAccountsPayablePhone;
		delete opportunity.prefix;

		try {
			const url = api + "/opportunity/update/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				openNotification("success", "Success", data.message);
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const updateOpportunityStatus = async () => {
		const opportunity = {
			status: "Lost",
		};

		try {
			const url = api + "/opportunity/update/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				const payload = data.payload;

				openNotification("success", "Success", data.message);

				setTimeout(() => {
					callback(payload.status);
					setLoading(false);
				}, 2000);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const requestApprovalToAdmins = async () => {
		try {
			const url = api + "/opportunity/request/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.post(url);
			if (data.success) {
				// const payload = data.payload;

				openNotification("success", "Success", data.message);

				setTimeout(() => {
					// callback(payload.status);
					setLoading(false);
				}, 2000);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to send request");
			console.error(err.message);
			return null;
		}
	};

	const onChangeContactType = (e) => {
		setContactType(e.target.value);
	};

	const onFinish = (values) => {
		setLoading(true);
		updateOpportunity(values);
	};

	const onLostOpportunity = () => {
		setLoading(true);
		updateOpportunityStatus();
	};

	const onRequestOpportunity = () => {
		setLoading(true);
		requestApprovalToAdmins();
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
				defaultValue={"+1"}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Company Name"
							name="companyName"
							rules={[
								{
									required: true,
									message: "Please fill company name",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Customer Type"
							name="companyCustomerType"
							rules={[
								{
									required: true,
									message: "Please select customer type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select customer type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={customerTypeOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Quadrant Type"
							name="companyQuadrantType"
							rules={[
								{
									required: true,
									message: "Please select quadrant type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select quadrant type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={quadrantTypeOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Name"
							name="userContactName"
							rules={[
								{
									required: true,
									message: "Please fill name",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Phone"
							name="userContactPhone"
							rules={[
								{
									required: true,
									message: "Please fill phone",
								},
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input size="large" addonBefore={prefixSelector} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} md={7} lg={5}>
						<Form.Item
							name="companyContactType"
							label="Contact Type"
							rules={[
								{
									required: true,
									message: "Please pick a type!",
								},
							]}
						>
							<Radio.Group
								onChange={onChangeContactType}
								defaultValue={contactType}
								value={contactType}
								size="large"
							>
								<Radio.Button value="SMS">SMS</Radio.Button>
								<Radio.Button value="Email">Email</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
					{contactType === "Email" ? (
						<Col span={24} md={17} lg={19}>
							<Form.Item
								label="Contact Email"
								name="userContactEmail"
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail",
									},
									{
										required: true,
										message: "Please fill email",
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
					) : (
						<Col span={24} md={17} lg={19}>
							<Form.Item
								label="Customer Phone"
								name="userContactSMS"
								rules={[
									{
										required: true,
										message: "Please fill phone",
									},
									{
										len: 10,
										max: 10,
										message: "Phone number must have 10 numbers",
									},
								]}
							>
								<Input size="large" addonBefore={prefixSelector} />
							</Form.Item>
						</Col>
					)}
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Company Address"
							name="companyAddress"
							rules={[
								{
									required: true,
									message: "Please fill address",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company City"
							name="companyCity"
							rules={[
								{
									required: true,
									message: "Please fill city",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company State"
							name="companyState"
							rules={[
								{
									required: true,
									message: "Please fill state",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company ZIP"
							name="companyZip"
							rules={[
								{
									required: true,
									message: "Please fill zip",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="A/P Contact Name"
							name="companyAccountsPayableContact"
							rules={[
								{
									required: true,
									message: "Please fill contact name",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="A/P Contact Email"
							name="companyAccountsPayableEmail"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
								{
									required: true,
									message: "Please fill contact email",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="A/P Contact Phone"
							name="companyAccountsPayablePhone"
							rules={[
								{
									required: true,
									message: "Please fill phone",
								},
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input size="large" addonBefore={prefixSelector} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12} sm={5} md={4} lg={3}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							size="large"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
						>
							Save
						</Button>
					</Col>
					{!approved && (
						<Col span={12} sm={10} md={7} lg={5}>
							<Button
								type="default"
								htmlType="button"
								loading={loading}
								size="large"
								style={{
									width: "100%",
									marginBottom: "10px",
								}}
								onClick={onRequestOpportunity}
							>
								Request Approval
							</Button>
						</Col>
					)}
					<Col span={12} sm={10} md={7} lg={5}>
						<Button
							type="primary"
							danger
							htmlType="button"
							loading={loading}
							size="large"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
							onClick={onLostOpportunity}
							disabled={status === "Lost" || status === "Closed"}
						>
							Lost Opportunity
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<div>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</div>
	);
};

export default OpportunityContact;
