import React from "react";
import { Flex, Tabs, Tag } from "antd";
import Layout from "../layout/Layout";
import OpportunityQuadrantType from "./OpportunityQuadrantType";

const OpportunityQuadrants = ({ history, match }) => {
	const tabOptions = [
		{
			label: "Mission Quadrant",
			key: "mission",
			children: (
				<OpportunityQuadrantType type={"mission"} map={match.params.id} />
			),
		},
		{
			label: "San Benito Quadrant",
			key: "sanbenito",
			children: (
				<OpportunityQuadrantType type={"sanbenito"} map={match.params.id} />
			),
		},
		{
			label: "Other Quadrant",
			key: "other",
			children: (
				<OpportunityQuadrantType type={"other"} map={match.params.id} />
			),
		},
	];

	const RenderTabs = () => {
		return (
			<Tabs
				defaultActiveKey="mission"
				type="card"
				size={"large"}
				items={tabOptions}
			/>
		);
	};

	return (
		<Layout title="Opportunity Quadrants" description="Quadrants">
			<Flex vertical gap={"large"}>
				<RenderTabs />
				{match.params.id === "pinmap" && (
					<Flex justify="center">
						<Tag color="blue">New</Tag>
						<Tag color="gold">Localize</Tag>
						<Tag color="cyan">Contact</Tag>
						<Tag color="magenta">Negotiation</Tag>
						<Tag color="green">Closed</Tag>
						<Tag color="error">Lost</Tag>
					</Flex>
				)}
			</Flex>
		</Layout>
	);
};

export default OpportunityQuadrants;
