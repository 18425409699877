import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Button, notification, Row, Col } from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled } from "@ant-design/icons";
const { Text } = Typography;

const VehicleList = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [vehicleList, setVehicleList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) =>
								history.push(`/office/vehicle/update/${record.id}`)
							}
						/>
					</Row>
				);
			},
		},
		{
			title: "Vehicle No.",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Vehicle Type",
			dataIndex: "type",
			key: "type",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Year",
			dataIndex: "year",
			key: "year",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Make",
			dataIndex: "make",
			key: "make",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Model",
			dataIndex: "model",
			key: "model",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Vehicle ID Number (VIN)",
			dataIndex: "vin",
			key: "vin",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "License Plates",
			dataIndex: "plates",
			key: "plates",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "License Plates State",
			dataIndex: "state",
			key: "state",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Leased or Owned",
			dataIndex: "property",
			key: "property",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Sticker / Plates",
			dataIndex: "sticker",
			key: "sticker",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Driver",
			dataIndex: "driver",
			key: "driver",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Insurance",
			dataIndex: "insurance",
			key: "insurance",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Insurance Expiration",
			dataIndex: "insuranceExpiration",
			key: "insuranceExpiration",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getVehicleList();
	}, []);

	const getVehicleList = async () => {
		setLoading(true);

		const url = api + `/office/vehicle/list`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						type: item.type || "-",
						year: item.year || "-",
						make: item.make || "-",
						model: item.model || "-",
						vin: item.vin || "-",
						plates: item.plates || "-",
						state: item.state || "-",
						property: item.property || "-",
						sticker: item.sticker
							? Intl.DateTimeFormat("en-US", {
									month: "short",
									year: "numeric",
							  }).format(new Date(item.sticker))
							: "-",
						driver: item.driver || "-",
						insurance: item.insurance || "-",
						insuranceExpiration: item.insuranceExpiration
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.insuranceExpiration)
							  )
							: "-",
						number: item.number || "-",
					});
				});
				setVehicleList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewVehicle = () => {
		history.push("/office/vehicle/new");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gutter={[16, 8]}>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickNewVehicle}
							size="large"
							loading={loading}
						>
							New vehicle
						</Button>
					</Col>
				</Row>
				&nbsp;
				<CustomTable columns={columns} data={vehicleList} />
			</Flex>
		);
	};

	return (
		<Layout title="Office Vehicles" description="Vehicle list">
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default VehicleList;
