import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";

const optionsVehicleType = [
	{
		value: "Straight Trucks",
		label: "Straight Trucks",
	},
];

const optionsVehicleProperty = [
	{
		value: "Owned",
		label: "Owned",
	},
	{
		value: "Leased",
		label: "Leased",
	},
];

const OfficeTruck = ({ history, match }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [initialValues, setInitialValues] = useState({});
	const [loadingButton, setLoadingButton] = useState(false);
	const [loanList, setLoanList] = useState([]);
	const [overAxiePermitPending, setOverAxiePermitPending] = useState(false);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLoans();
		getVehicle();
	}, []);

	const getLoans = async () => {
		try {
			const url = api + "/loan/list/options";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.owner} - ${item.lender} - ${item.collateral}`,
						value: item._id,
					});
				});
				setLoanList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Error while trying to create vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const getVehicle = async () => {
		const url = api + `/office/vehicle/get/${match.params.id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				const payload = data.payload;
				setInitialValues({
					type: payload.type,
					year: payload.year,
					make: payload.make,
					model: payload.model,
					vin: payload.vin,
					plates: payload.plates,
					state: payload.state,
					property: payload.property,
					sticker: payload.sticker ? dayjs(payload.sticker) : undefined,
					loan: payload.loan || undefined,
					texasReadyMixPermit: payload.texasReadyMixPermit
						? dayjs(payload.texasReadyMixPermit)
						: undefined,
					permitNumber1: payload.permitNumber1 || undefined,
					overAxiePermit: payload.overAxiePermit
						? dayjs(payload.overAxiePermit)
						: undefined,
					overAxiePermitPending: payload.overAxiePermitPending || false,
					permitNumber2: payload.permitNumber2 || undefined,
					replacementDieselTags: payload.replacementDieselTags || undefined,
					comments: payload.comments || undefined,
					number: payload.number || undefined,
					gvwr: payload.gvwr || undefined,
					registration: payload.registration
						? dayjs(payload.registration)
						: undefined,
					insuranceExpiration: payload.insuranceExpiration
						? dayjs(payload.insuranceExpiration)
						: undefined,
					readyMixPermit: payload.readyMixPermit
						? dayjs(payload.readyMixPermit)
						: undefined,
					inspection: payload.inspection
						? dayjs(payload.inspection)
						: undefined,
					overweight: payload.overweight
						? dayjs(payload.overweight)
						: undefined,
					insuranceCabCard: payload.insuranceCabCard
						? dayjs(payload.insuranceCabCard)
						: undefined,
					annualInspection: payload.annualInspection
						? dayjs(payload.annualInspection)
						: undefined,
					drugTest: payload.drugTest || false,
					cleaning: payload.cleaning || undefined,
					observations: payload.observations || undefined,
					book: payload.book || false,
				});

				setOverAxiePermitPending(payload.overAxiePermitPending);
			} else {
				setLoadingButton(true);
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoadingButton(true);
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get vehicle");
			console.error(err.message);
			return null;
		}
	};

	const updateVehicle = async (data) => {
		const vehicle = {
			...data,
			sticker: data.sticker ? dayjs(data.sticker).toDate() : undefined,
			overAxiePermit: data.overAxiePermit
				? dayjs(data.overAxiePermit).toDate()
				: undefined,
			texasReadyMixPermit: data.texasReadyMixPermit
				? dayjs(data.texasReadyMixPermit).toDate()
				: undefined,
			registration: data.registration
				? dayjs(data.registration).toDate()
				: undefined,
			insuranceExpiration: data.insuranceExpiration
				? dayjs(data.insuranceExpiration).toDate()
				: undefined,
			readyMixPermit: data.readyMixPermit
				? dayjs(data.readyMixPermit).toDate()
				: undefined,
			inspection: data.inspection ? dayjs(data.inspection).toDate() : undefined,
			overweight: data.overweight ? dayjs(data.overweight).toDate() : undefined,
			insuranceCabCard: data.insuranceCabCard
				? dayjs(data.insuranceCabCard).toDate()
				: undefined,
			annualInspection: data.annualInspection
				? dayjs(data.annualInspection).toDate()
				: undefined,
		};

		try {
			let url = api + `/office/vehicle/update/${match.params.id}`;

			const { data } = await axios.patch(url, vehicle);
			if (data.success) {
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/office/truck/list");
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
				setLoadingButton(false);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = (values) => {
		setLoadingButton(true);
		updateVehicle(values);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onChangeOverAxiePermitPending = (e) => {
		setOverAxiePermitPending(e.target.checked);
		form.setFieldValue("overAxiePermitPending", e.target.checked);
		if (e.target.checked) {
			form.setFieldValue("overAxiePermit", undefined);
		}
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Truck No."
							name="number"
							rules={[{ required: true, message: "Truck Number is required" }]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item label="Vehicle Type" name="type">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select type"
								options={optionsVehicleType}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Year"
							name="year"
							rules={[
								{
									required: true,
									message: "Year is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Make"
							name="make"
							rules={[
								{
									required: true,
									message: "Make is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Model"
							name="model"
							rules={[
								{
									required: true,
									message: "Model is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Gross Vehicle Weight Rating"
							name="gvwr"
							rules={[
								{
									required: true,
									message: "GVWR is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Vehicle ID Number (VIN)"
							name="vin"
							rules={[
								{
									required: true,
									message: "VIN is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="License Plates"
							name="plates"
							rules={[
								{
									required: true,
									message: "Plates are required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="License Plate State"
							name="state"
							rules={[
								{
									required: true,
									message: "State is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="Property" name="property">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a property"
								options={optionsVehicleProperty}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Sticker / Plates"
							name="sticker"
							rules={[
								{
									required: true,
									message: "Sticker is required",
								},
							]}
						>
							<DatePicker
								format={"MMMM - YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Texas Ready Mix Permit Exp"
							name="texasReadyMixPermit"
						>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={6}>
						<Form.Item label="Permit Number" name="permitNumber1">
							<Input size="large" />
						</Form.Item>
					</Col>
					{!overAxiePermitPending && (
						<Col span={24} sm={6}>
							<Form.Item label="Over Axie Permit" name="overAxiePermit">
								<DatePicker
									size="large"
									style={{ width: "100%" }}
									format={"MM/DD/YYYY"}
									allowClear
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={24} sm={6}>
						<Form.Item
							label="Is Pending?"
							name="overAxiePermitPending"
							valuePropName="checked"
						>
							<Checkbox
								onChange={onChangeOverAxiePermitPending}
								checked={overAxiePermitPending}
							>
								Over Axie Permit Is Pending?
							</Checkbox>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Permit Number" name="permitNumber2">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} xs={12}>
						<Form.Item label="Comments" name="comments">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} xs={12}>
						<Form.Item
							label="Replacement Diesel Tags"
							name="replacementDieselTags"
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Loan" name="loan">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select loan"
								options={loanList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={6}>
						<Form.Item label="Registration" name="registration">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Insurance" name="insuranceExpiration">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Ready Mix Permit" name="readyMixPermit">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Inspection" name="inspection">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Overweight" name="overweight">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Insurance Cab Card" name="insuranceCabCard">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item
							label="Drug Test?"
							name="drugTest"
							valuePropName="checked"
						>
							<Checkbox>Drug Test</Checkbox>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Annual Inspection" name="annualInspection">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Cleaning Check List" name="cleaning">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Observations" name="observations">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item label="Book?" name="book" valuePropName="checked">
							<Checkbox>Book</Checkbox>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout
			title="Update Truck"
			description="Update truck attributes"
			type="medium"
		>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default OfficeTruck;
