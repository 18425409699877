import { Button, Col, Flex, Row, Typography } from "antd";
import React from "react";
const { Title } = Typography;

const Home = ({ history }) => {
	const onClickAction = (value) => {
		switch (value) {
			case "login":
				history.push("/signin");
				break;
			case "customer":
				history.push("/nuevocliente");
				break;
			case "order":
				history.push("/certform");
				break;
			case "employee":
				history.push("/newemployee");
				break;
			case "opportunity":
				history.push("/opportunity/new");
				break;
			default:
				break;
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(${
					"/images/portada.jpg"
					// process.env.PUBLIC_URL + "/images/portada.jpg"
				})`,
				/* Full height */
				height: "100vh",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<Flex gap={"middle"} vertical justify="flex-start" align="center">
				{/* <img
					src={"/images/concrete57.png"}
					// src={process.env.REACT_APP_PUBLIC_URL + "/images/concrete57.png"}
					// src={"https://jap.57concrete.com/client/public/images/concrete57.png"}
					// src="http://57concrete.com/assets/images/logo/logo-light.png"
					alt="Logo"
					style={{ width: "30%", padding: "20px 0px" }}
				/> */}
				<Title level={1}>JAP</Title>
				<Row gutter={16} style={{ width: 300 }}>
					<Col span={24}>
						<Button
							type="primary"
							size="large"
							style={{ width: "100%" }}
							onClick={(e) => onClickAction("login")}
						>
							Login
						</Button>
					</Col>
					&nbsp;
					<Col span={24}>
						<Button
							type="primary"
							danger
							size="large"
							style={{ width: "100%" }}
							onClick={(e) => onClickAction("customer")}
						>
							Customer Registration
						</Button>
					</Col>
					&nbsp;
					<Col span={24}>
						<Button
							type="primary"
							size="large"
							style={{ width: "100%" }}
							onClick={(e) => onClickAction("order")}
						>
							Order your concrete
						</Button>
					</Col>
					&nbsp;
					<Col span={24}>
						<Button
							type="primary"
							danger
							size="large"
							style={{ width: "100%" }}
							onClick={(e) => onClickAction("employee")}
						>
							Employee Registration
						</Button>
					</Col>
					&nbsp;
					<Col span={24}>
						<Button
							type="primary"
							size="large"
							style={{ width: "100%" }}
							onClick={(e) => onClickAction("opportunity")}
						>
							Opportunity Registration
						</Button>
					</Col>
				</Row>
			</Flex>
		</div>
	);
};

export default Home;
