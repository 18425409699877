import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	notification,
	Statistic,
	Row,
	Button,
	Popconfirm,
	Form,
	Table,
	Input,
	Col,
} from "antd";
import { EditFilled, SaveFilled, CloseOutlined } from "@ant-design/icons";
import { CustomSpinner } from "../utils/CustomComponents";
const { Text } = Typography;

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	...restProps
}) => {
	const inputNode = <Input />;
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const LoanPayments = ({ id }) => {
	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [paymentList, setPaymentList] = useState([]);
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState("");
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const updateLoan = async (paymentId, invoice) => {
		const loanPayment = {
			paymentId,
			invoice,
		};

		try {
			let url = api + "/loan/update/payments/" + id;

			const { data } = await axios.patch(url, loanPayment);
			if (data.success) {
				openNotification("success", "Success", data.message);
				setLoadingButton(false);
				return true;
			} else {
				openNotification("error", "Error", data.message);
				setLoadingButton(false);
				return false;
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Error while trying to update loan payment"
			);
			setLoadingButton(false);
			console.error(err.message);
			return false;
		}
	};

	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			invoice: record.invoice,
		});
		setEditingKey(record.key);
	};
	const cancel = () => {
		setEditingKey("");
	};

	const save = async (record) => {
		try {
			setLoadingButton(true);
			const row = await form.validateFields();
			const newData = [...paymentList];
			const updated = await updateLoan(record.id, row.invoice);

			if (updated) {
				const index = newData.findIndex((item) => record.key === item.key);
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				setPaymentList(newData);
				setEditingKey("");
			} else {
				setPaymentList(newData);
				setEditingKey("");
			}
		} catch (errInfo) {
			setLoadingButton(false);
			console.log("Validate Failed:", errInfo);
		}
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Pmt No.",
			dataIndex: "key",
			key: "key",
			render: (value) => {
				return <Text strong>{value + 1}</Text>;
			},
		},
		{
			title: "Payment Date",
			dataIndex: "paymentDate",
			key: "paymentDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Beggining Balance",
			dataIndex: "begginingBalance",
			key: "begginingBalance",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Payment",
			dataIndex: "payment",
			key: "payment",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Principal",
			dataIndex: "principal",
			key: "principal",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Interest",
			dataIndex: "interest",
			key: "interest",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Ending Balance",
			dataIndex: "endingBalance",
			key: "endingBalance",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Invoice",
			dataIndex: "invoice",
			key: "invoice",
			editable: true,
		},
		{
			title: "Operations",
			dataIndex: "operation",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<Row gutter={16} justify={"center"}>
						<Col>
							<Button
								type="primary"
								icon={<SaveFilled twoToneColor={"white"} />}
								onClick={() => save(record)}
								loading={loadingButton}
							/>
						</Col>
						<Col>
							<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
								<Button
									type="primary"
									danger
									icon={<CloseOutlined twoToneColor={"white"} />}
									loading={loadingButton}
								/>
							</Popconfirm>
						</Col>
					</Row>
				) : (
					<Row gutter={16} justify={"center"}>
						<Col>
							<Button
								type="primary"
								icon={<EditFilled twoToneColor={"white"} />}
								disabled={editingKey !== ""}
								onClick={() => edit(record)}
							/>
						</Col>
					</Row>
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: "text",
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	useEffect(() => {
		getPaymentList();
	}, []);

	const getPaymentList = async () => {
		setLoading(true);

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/loan/list/payments/${id}`;

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.payments.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						begginingBalance: item.begginingBalance,
						endingBalance: item.endingBalance,
						interest: item.interest,
						payment: item.payment,
						principal: item.principal,
						paymentDate: new Intl.DateTimeFormat("en-US").format(
							new Date(item.paymentDate)
						),
						invoice: item.invoice || "",
					});
				});
				setPaymentList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Form form={form} component={false}>
				<Table
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					bordered
					dataSource={paymentList}
					columns={mergedColumns}
					rowClassName="editable-row"
					pagination={{
						onChange: cancel,
					}}
				/>
			</Form>
		);
	};

	return (
		<div>
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</div>
	);
};

export default LoanPayments;
