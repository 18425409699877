import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

const googleApi =
	process.env.REACT_APP_GOOGLE_MAP_API ||
	"AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs";

const GoogleMap = ({ children, ...props }) => (
	<div style={{ height: "60vh", width: "100%" }}>
		<GoogleMapReact
			bootstrapURLKeys={{
				key: googleApi,
			}}
			hoverDistance={40}
			{...props}
		>
			{children}
		</GoogleMapReact>
	</div>
);

GoogleMap.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

GoogleMap.defaultProps = {
	children: null,
};

export default GoogleMap;
