import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";

const loanTypeOptions = [
	{
		label: "RPO",
		value: "RPO",
	},
	{
		label: "EFA",
		value: "EFA",
	},
	{
		label: "REFI",
		value: "REFI",
	},
	{
		label: "LOC",
		value: "LOC",
	},
];

const Loan = ({ history, match }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [initialValues, setInitialValues] = useState({});

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLoan();
	}, []);

	const getLoan = async () => {
		setLoading(true);
		try {
			const url = api + "/loan/get/" + match.params.id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.get(url);
			if (data.success) {
				const loan = data.payload;
				setInitialValues({
					owner: loan.owner,
					type: loan.type,
					project: loan.project,
					lender: loan.lender,
					collateral: loan.collateral,
					cost: loan.cost,
					salesTax: loan.salesTax,
					fees: loan.fees,
					totalCost: loan.totalCost,
					downPayment: loan.downPayment,
					advancedPayment: loan.advancedPayment,
					totalAmountFinanced: loan.totalAmountFinanced,
					acceleratedPayments: loan.acceleratedPayments,
					regularPayment: loan.regularPayment,
					financeCharge: loan.financeCharge,
					rate: loan.rate,
					period: loan.period,
					totalCostLoan: loan.totalCostLoan,
					initialPaymentDate: dayjs(loan.initialPaymentDate),
				});
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get loan");
			console.error(err.message);
			return null;
		}
	};
	const deleteLoan = async () => {
		setLoadingButton(true);
		try {
			const url = api + "/loan/delete/" + match.params.id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.delete(url);
			if (data.success) {
				openNotification("success", "Success", data.message);
				setTimeout(() => {
					setLoadingButton(false);
					history.push("/loan/dashboard");
				}, 2000);
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification("error", "Error", "Error while trying to get loan");
			console.error(err.message);
			return null;
		}
	};

	const updateLoan = async (data) => {
		const loan = {
			// ...data,
			owner: data.owner,
			type: data.type,
			project: data.project,
			lender: data.lender,
			collateral: data.collateral,
		};

		try {
			let url = api + "/loan/update/" + match.params.id;

			const { data } = await axios.patch(url, loan);
			if (data.success) {
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/loan/dashboard");
				}, 2000);
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification("error", "Error", "Error while trying to update loan");
			console.error(err.message);
			return null;
		}
	};

	const onFinish = () => {
		setLoadingButton(true);
		const values = form.getFieldsValue();
		// const payments = calculatePayments();
		let finalValues = {
			...values,
			// payments,
			// initialPaymentDate: dayjs(values.initialPaymentDate).toDate(),
		};
		updateLoan(finalValues);
	};

	// const onChangeCost = () => {
	// 	const { cost, salesTax, fees, downPayment } = form.getFieldsValue();
	// 	let currentCost = cost || 0;
	// 	let currentTax = salesTax || 0;
	// 	let currentFees = fees || 0;
	// 	const total = currentCost + currentTax + currentFees;
	// 	form.setFieldValue("totalCost", parseFloat(total.toFixed(3)));

	// 	let currentDown = downPayment || 0;

	// 	const totalFinanced = total - currentDown;
	// 	form.setFieldValue(
	// 		"totalAmountFinanced",
	// 		parseFloat(totalFinanced.toFixed(3))
	// 	);
	// 	calculateTableRPO();
	// };

	// const onChangePayment = () => {
	// 	const { downPayment, totalCost } = form.getFieldsValue();
	// 	let currentDown = downPayment || 0;
	// 	let currentTotalCost = totalCost || 0;
	// 	const total = currentTotalCost - currentDown;
	// 	form.setFieldValue("totalAmountFinanced", parseFloat(total.toFixed(3)));
	// 	calculateTableRPO();
	// };

	// const calculateTableRPO = () => {
	// 	const period = form.getFieldValue("period");
	// 	const rate = form.getFieldValue("rate");
	// 	const totalAmountFinanced = form.getFieldValue("totalAmountFinanced");

	// 	const pRegularPayment = rate / 12 / 100;
	// 	const regularPayments =
	// 		totalAmountFinanced /
	// 		((1 - Math.pow(1 + pRegularPayment, -period)) / pRegularPayment);

	// 	const regular = parseFloat(regularPayments.toFixed(2));

	// 	if (isNaN(regular) || !isFinite(regular)) {
	// 		form.setFieldValue("regularPayment", 0);
	// 		form.setFieldValue("financeCharge", 0);
	// 		form.setFieldValue("totalCostLoan", 0);
	// 	} else {
	// 		form.setFieldValue("regularPayment", regular);

	// 		let begginingBalance = totalAmountFinanced;
	// 		let financeCharge = 0;
	// 		for (let index = 0; index < period; index++) {
	// 			const interest = parseFloat(
	// 				(pRegularPayment * begginingBalance).toFixed(2)
	// 			);
	// 			const principal = parseFloat((regular - interest).toFixed(2));

	// 			let endingBalance = parseFloat(
	// 				(begginingBalance - principal).toFixed(2)
	// 			);

	// 			if (endingBalance <= 0) endingBalance = 0;

	// 			financeCharge += interest;

	// 			begginingBalance = endingBalance.toFixed(2);
	// 		}
	// 		form.setFieldValue("financeCharge", parseFloat(financeCharge.toFixed(2)));
	// 		form.setFieldValue(
	// 			"totalCostLoan",
	// 			parseFloat((totalAmountFinanced + financeCharge).toFixed(2))
	// 		);
	// 	}
	// };

	// const calculatePayments = () => {
	// 	const period = form.getFieldValue("period");
	// 	const rate = form.getFieldValue("rate");
	// 	const totalAmountFinanced = parseFloat(
	// 		form.getFieldValue("totalAmountFinanced")
	// 	);
	// 	const pRegularPayment = rate / 12 / 100;
	// 	const regularPayments =
	// 		totalAmountFinanced /
	// 		((1 - Math.pow(1 + pRegularPayment, -period)) / pRegularPayment);

	// 	const regular = parseFloat(regularPayments.toFixed(2));

	// 	if (!isNaN(regular) || isFinite(regular)) {
	// 		let initialPaymentDate = form.getFieldValue("initialPaymentDate");

	// 		let RPOArray = [];
	// 		let begginingBalance = totalAmountFinanced;

	// 		for (let index = 0; index < period; index++) {
	// 			const interest = parseFloat(
	// 				(pRegularPayment * begginingBalance).toFixed(2)
	// 			);
	// 			const principal = parseFloat((regular - interest).toFixed(2));

	// 			let endingBalance = parseFloat(
	// 				(begginingBalance - principal).toFixed(2)
	// 			);

	// 			if (endingBalance <= 0) endingBalance = 0;

	// 			let date = dayjs(initialPaymentDate).add(index * 7, "day");

	// 			RPOArray.push({
	// 				paymentDate: date.toDate(),
	// 				begginingBalance,
	// 				payment: regular,
	// 				principal,
	// 				interest,
	// 				endingBalance,
	// 			});

	// 			begginingBalance = endingBalance.toFixed(2);
	// 		}
	// 		return RPOArray;
	// 	}
	// 	return [];
	// };

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={18}>
						<Form.Item
							label="Owner/Lessor"
							name="owner"
							rules={[
								{
									required: true,
									message: "Owner is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={6}>
						<Form.Item
							label="Type of Loan"
							name="type"
							rules={[
								{
									required: true,
									message: "Loan type is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select loan type"
								options={loanTypeOptions}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Project"
							name="project"
							rules={[
								{
									required: true,
									message: "Project is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Lender"
							name="lender"
							rules={[
								{
									required: true,
									message: "Lender is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Collateral"
							name="collateral"
							rules={[
								{
									required: true,
									message: "Collateral is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={6}>
						<Form.Item
							label="Cost"
							name="cost"
							rules={[
								{
									required: true,
									message: "Cost is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								// onChange={onChangeCost}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Sales Tax / Plates" name="salesTax">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								// onChange={onChangeCost}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Fees" name="fees">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								// onChange={onChangeCost}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Total Cost" name="totalCost">
							<InputNumber
								disabled
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={8}>
						<Form.Item label="Down Payment" name="downPayment">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								// onChange={onChangePayment}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item label="Advanced Payment" name="advancedPayment">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item label="Total Amount Financed" name="totalAmountFinanced">
							<InputNumber
								disabled
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Accelerated Payments" name="acceleratedPayments">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Regular Payment" name="regularPayment">
							<InputNumber
								disabled
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Finance Charge" name="financeCharge">
							<InputNumber
								disabled
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item
							label="Rate (%)"
							name="rate"
							rules={[
								{
									required: true,
									message: "Rate is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								defaultValue={0.01}
								min={0.01}
								max={100}
								step={"0.01"}
								// onChange={calculateTableRPO}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item
							label="Period (Days)"
							name="period"
							rules={[
								{
									required: true,
									message: "Period is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								defaultValue={1}
								min={1}
								// onChange={calculateTableRPO}
								disabled
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Total cost of Loan" name="totalCostLoan">
							<InputNumber
								disabled
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={6}>
						<Form.Item label="Initial Payment Date" name="initialPaymentDate">
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear={false}
								disabled
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={[16, 6]}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							danger
							htmlType="button"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
							onClick={deleteLoan}
						>
							Delete
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout
			title="Update Loan"
			description="Edit current loan values"
			type="medium"
		>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default Loan;
