import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";

const optionsVehicleType = [
	{
		value: "Pick Up",
		label: "Pick Up",
	},
	{
		value: "Car",
		label: "Car",
	},
];

const optionsVehicleProperty = [
	{
		value: "Owned",
		label: "Owned",
	},
	{
		value: "Leased",
		label: "Leased",
	},
];

const Vehicle = ({ history, match }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [initialValues, setInitialValues] = useState({});
	const [loadingButton, setLoadingButton] = useState(false);
	const [loanList, setLoanList] = useState([]);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLoans();
		getVehicle();
	}, []);

	const getLoans = async () => {
		try {
			const url = api + "/loan/list/options";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.owner} - ${item.lender} - ${item.collateral}`,
						value: item._id,
					});
				});
				setLoanList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Error while trying to create vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const getVehicle = async () => {
		const url = api + `/office/vehicle/get/${match.params.id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				const payload = data.payload;
				setInitialValues({
					type: payload.type,
					year: payload.year,
					make: payload.make,
					model: payload.model,
					vin: payload.vin,
					plates: payload.plates,
					state: payload.state,
					property: payload.property,
					sticker: payload.sticker ? dayjs(payload.sticker) : undefined,
					insuranceExpiration: payload.insuranceExpiration
						? dayjs(payload.insuranceExpiration)
						: undefined,
					insurance: payload.insurance,
					driver: payload.driver,
					loan: payload.loan || undefined,
					number: payload.number || undefined,
				});
			} else {
				setLoadingButton(true);
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoadingButton(true);
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get vehicle");
			console.error(err.message);
			return null;
		}
	};

	const updateVehicle = async (data) => {
		const vehicle = {
			...data,
			insuranceExpiration: data.insuranceExpiration
				? dayjs(data.insuranceExpiration).toDate()
				: undefined,
			sticker: data.sticker ? dayjs(data.sticker).toDate() : undefined,
		};

		try {
			let url = api + `/office/vehicle/update/${match.params.id}`;

			const { data } = await axios.patch(url, vehicle);
			if (data.success) {
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/office/vehicle/list");
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
				setLoadingButton(false);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = (values) => {
		setLoadingButton(true);
		updateVehicle(values);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Vehicle No."
							name="number"
							rules={[
								{ required: true, message: "Vehicle Number is required" },
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item label="Vehicle Type" name="type">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select type"
								options={optionsVehicleType}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item label="Year" name="year">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Make"
							name="make"
							rules={[
								{
									required: true,
									message: "Make is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Model"
							name="model"
							rules={[
								{
									required: true,
									message: "Model is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Vehicle ID Number (VIN)"
							name="vin"
							rules={[
								{
									required: true,
									message: "VIN is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="License Plates" name="plates">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="License Plate State" name="state">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Property"
							name="property"
							rules={[
								{
									required: true,
									message: "Property is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a property"
								options={optionsVehicleProperty}
								showSearch
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="Sticker / Plates" name="sticker">
							<DatePicker
								format={"MMMM - YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="Driver" name="driver">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Insurance" name="insurance">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Insurance Expiration" name="insuranceExpiration">
							<DatePicker
								format={"MM/DD/YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Loan" name="loan">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select loan"
								options={loanList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout
			title="Update Vehicle"
			description="Update vehicle attributes"
			type="medium"
		>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default Vehicle;
