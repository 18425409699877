import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import { Typography, Flex, Modal, Button, Table, Statistic } from "antd";
import dayjs from "dayjs";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import EticketGeolocation from "../map/EticketGeolocation";
const { Text } = Typography;

const ETicketTripsReport = () => {
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [loading, setLoading] = useState(true);
	const [loadList, setLoadList] = useState([]);
	const [open, setOpen] = useState(false);
	const locations = useRef([]);
	const origin = useRef({});
	const destination = useRef({});

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Truck",
			dataIndex: "truck",
			key: "truck",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Completed",
			dataIndex: "completed",
			key: "completed",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						precision={0}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Incompleted",
			dataIndex: "incompleted",
			key: "incompleted",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						precision={0}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
	];

	const expandedRowRender = (row) => {
		const columns = [
			{
				title: "Date",
				dataIndex: "date",
				key: "date",
				render: (value) => {
					return <Text strong>{value}</Text>;
				},
			},
			{
				title: "Load ID",
				dataIndex: "loadId",
				key: "loadId",
				render: (value) => {
					return <Text strong>{value}</Text>;
				},
			},
			{
				title: "Truck",
				dataIndex: "truck",
				key: "truck",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Driver",
				dataIndex: "driver",
				key: "driver",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Type",
				dataIndex: "type",
				key: "type",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Route",
				dataIndex: "route",
				key: "route",
				render: (_, values) => {
					return (
						<Button
							hidden={values.origin === null}
							onClick={() =>
								onOpen(values.locations, values.origin, values.destination)
							}
							disabled={values.origin === null}
						>
							Show Map
						</Button>
					);
				},
			},
		];

		return (
			<Table columns={columns} dataSource={row.trips} pagination={false} />
		);
	};

	useEffect(() => {
		getLoadList("");
	}, []);

	const getLoadList = async (term) => {
		setLoading(true);
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url = api + `/tripcoordinates/list?startDate=${start}&endDate=${end}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			let { data } = await axios.get(url);
			let list = [];

			data = data.filter((value) => value.locations.length > 0);

			let groupedData = Object.groupBy(data, ({ truck }) => truck);
			groupedData = Object.values(groupedData);
			groupedData.map((trips, index) => {
				list.push({
					key: trips[0].truck,
					id: trips[0].truck,
					truck: trips[0].truck,
					completed: trips.filter((truck) => truck.status === "Completed")
						.length,
					incompleted: trips.filter((truck) => truck.status === "Incompleted")
						.length,
					trips: trips.map((trip) => {
						return {
							key: trip._id,
							id: trip._id,
							date: new Intl.DateTimeFormat("en-US").format(
								new Date(trip.createdAt)
							),
							loadId: trip.load ? trip.load.deliveryTicket : "-",
							truck: trip.truck,
							driver: trip.driver.name,
							type: trip.type,
							status: trip.status,
							locations: trip.locations,
							origin: trip.load
								? {
										latitude: trip.load.job.plant.latitude,
										longitude: trip.load.job.plant.longitude,
								  }
								: null,
							destination: trip.load
								? {
										latitude: trip.load.job.latitude,
										longitude: trip.load.job.longitude,
								  }
								: null,
						};
					}),
				});
			});
			list = list.sort((a, b) => a.truck - b.truck);
			setLoadList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getLoadList(value);
		}
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const RenderDates = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				dateFormat={"MM/DD/YYYY"}
				onRangeChange={onRangeChange}
				onSearch={onSearch}
			/>
		);
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Table
					columns={columns}
					dataSource={loadList}
					expandable={{
						expandedRowRender,
					}}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100],
						defaultPageSize: 10,
						showSizeChanger: true,
					}}
					scroll={{
						x: true,
					}}
					style={{
						minWidth: "100%",
					}}
				/>
			</Flex>
		);
	};

	const onOpen = (locationList, originCoords, destinationCoords) => {
		locations.current = locationList;
		origin.current = originCoords;
		destination.current = destinationCoords;
		setOpen(true);
	};

	const onCancel = () => {
		setOpen(false);
	};

	const RenderGoogleMaps = () => {
		return (
			<Modal
				title={"Directions from truck"}
				open={open}
				onCancel={onCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<EticketGeolocation
					locations={locations.current}
					origin={origin.current}
					destination={destination.current}
				/>
			</Modal>
		);
	};

	return (
		<Layout
			title="ETicket Trips"
			description="Report"
			className="container col-md-10"
		>
			<Flex vertical gap={"large"}>
				<RenderDates />
				{loading ? <CustomSpinner /> : <RenderTable />}
				<RenderGoogleMaps />
			</Flex>
		</Layout>
	);
};

export default ETicketTripsReport;
