import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { useHistory } from "react-router-dom";
import formatDate from "../utils/FormatDate";
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
	Tag,
	Typography,
} from "antd";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import {
	PlusCircleFilled,
	DeleteFilled,
	FilePdfFilled,
} from "@ant-design/icons";

const { Text } = Typography;

const optionsDate = {
	hour: "numeric",
	minute: "numeric",
	second: "numeric",
	hour12: true,
};

const LoadJob = ({ pcb, job }) => {
	const [loads, setLoads] = useState([]);
	const [loadSum, setLoadSum] = useState("");
	// const [driver, setDriver] = useState("");
	// const [driverId, setDriverId] = useState("");
	const [newLoad, setNewLoad] = useState("");
	// const [loadChange, setLoadChange] = useState(false);
	// const [deliveryTicket, setDeliveryTicket] = useState("");
	// const [orderCode, setOrderCode] = useState("");
	// const [driverSelected, setDriverSelected] = useState(true);
	// const [trucks, setTrucks] = useState([]);
	// const [truckNumber, setTruckNumber] = useState("");
	// const [sequence, setSequence] = useState();
	// const [newTruck, setNewTruck] = useState({});
	let history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(true);
	const [loadingTable, setLoadingTable] = useState(true);
	const [optionsDrivers, setOptionsDrivers] = useState([]);
	const [loadingDriver, setLoadingDriver] = useState(true);
	const [optionsTrucks, setOptionsTrucks] = useState([]);
	const [loadingTruck, setLoadingTruck] = useState(true);
	const [apiNotification, contextHolder] = notification.useNotification();
	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Driver",
			dataIndex: "driver",
			key: "driver",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Truck",
			dataIndex: "truck",
			key: "truck",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Load (YD3)",
			dataIndex: "yards",
			key: "yards",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "In Plant":
						color = "blue";
						break;
					case "In Progress":
						color = "gold";
						break;
					case "Arrived":
						color = "green";
						break;
					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: "Difference",
			dataIndex: "difference",
			key: "difference",
			render: (value) => {
				return <Text type={value <= 0 ? "success" : "danger"}>{value}</Text>;
			},
		},
		{
			title: "Ticket",
			dataIndex: "deliveryTicket",
			key: "deliveryTicket",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Order Code",
			dataIndex: "orderCode",
			key: "orderCode",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Sequence",
			dataIndex: "sequence",
			key: "sequence",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Actions",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				const hyperlink = `http://ec2-3-85-198-38.compute-1.amazonaws.com/${record.id}`;
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							href={hyperlink}
							target="_blank"
							icon={<FilePdfFilled twoToneColor={"white"} />}
							// onClick={() => history.push(`/e-ticket/${record.id}`)}
						/>
						&nbsp;
						<Button
							type="primary"
							danger
							icon={<DeleteFilled twoToneColor={"white"} />}
							disabled={
								!(
									localStorage.getItem("email") === "vm@57concrete.com" ||
									localStorage.getItem("email") === "fc@57concrete.com" ||
									localStorage.getItem("email") === "jp@57concrete.com" ||
									localStorage.getItem("email") === "ec@57concrete.com" ||
									localStorage.getItem("email") === "eliud@gmail.com"
								)
							}
							onClick={() => deleteLoad(record)}
						/>
					</Row>
				);
			},
		},
	];

	// useEffect(() => {
	// 	getLoadData();
	// 	// getDrivers();
	// }, [loadChange]);

	useEffect(() => {
		getInitialValues();
	}, []);

	const getInitialValues = async () => {
		await Promise.all([getDrivers(), getTrucks()]).then(() => {
			getLoadData();
			setLoading(false);
			setLoadingButton(false);
		});
	};

	const getDrivers = async () => {
		const url = api + "/driver/list/active";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			setOptionsDrivers([
				...data.map((driver) => {
					return {
						value: driver._id,
						label: driver.name.toUpperCase(),
					};
				}),
			]);
			setLoadingDriver(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getTrucks = async () => {
		const url = api + "/truck/list/active";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			setOptionsTrucks([
				...data.map((truck) => {
					return {
						value: truck._id,
						label: truck.number,
					};
				}),
			]);
			setLoadingTruck(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// const onChangeDriver = (selectedDriver) => {
	// 	if (selectedDriver) {
	// 		// const camion = trucks.find(
	// 		// 	(trompo) => trompo._id === selectedDriver.value
	// 		// );
	// 		// setTruckNumber(camion.truckNum);
	// 		setDriver(selectedDriver.label);
	// 		setDriverId(selectedDriver.value);
	// 	}
	// };

	// const onChangeTruck = (selectedTruck) => {
	// 	if (selectedTruck) {
	// 		setTruckNumber(selectedTruck.label);
	// 		setNewTruck(selectedTruck);
	// 	}
	// };

	const getLoadData = async () => {
		const url = api + "/load/job/" + job._id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			setLoads([
				...data.map((item) => {
					let status = "In Plant";
					let time = 0;
					if (item.job.destinationTime) {
						time = parseInt(item.job.destinationTime.split(" ")[0]);
					} else if (item.job.destinationMinutes) {
						time = item.job.destinationMinutes;
					}

					let minutes = 0;
					if (item.toJobDate && item.toArriveDate) {
						let toJobDate = new Date(item.toJobDate);
						let toArriveDate = new Date(item.toArriveDate);
						var difference = toArriveDate.getTime() - toJobDate.getTime(); // This will give difference in milliseconds
						minutes = Math.round(difference / 60000);
					}

					let diff = time - minutes;
					if (item.statusTrip === "Stand By") {
						status = "In Plant";
					} else if (item.statusTrip === "Start Trip") {
						status = "In Progress";
					} else {
						status = "Arrived";
					}
					return {
						id: item._id,
						key: item._id,
						driver: item.driver,
						orderCode: item.orderCode,
						date: new Intl.DateTimeFormat("en-US", optionsDate).format(
							new Date(item.date)
						),
						yards: item.yards,
						deliveryTicket: item.deliveryTicket,
						sequence: item.sequence,
						truck: item.truckNumber,
						difference: diff,
						status: status,
					};
				}),
			]);
			form.resetFields();
			const suma = data
				.map((viaje) => viaje.yards)
				.reduce((acc, acum) => acc + acum, 0);
			setLoadSum(suma);
			setLoadingTable(false);
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	// const getDrivers = async () => {
	// 	const url = api + "/driver/list";
	// 	const token = localStorage.getItem("token");
	// 	axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

	// 	try {
	// 		const { data } = await axios.get(url);
	// 		const chofer = data.map((chof) => {
	// 			const truckNum = chof.truck ? chof.truck.number : "0";
	// 			return {
	// 				_id: chof._id,
	// 				phone: chof.phone,
	// 				diverName: chof.name,
	// 				truckNum,
	// 			};
	// 		});
	// 		setTrucks(chofer);
	// 	} catch (err) {
	// 		console.error(err.message);
	// 		return null;
	// 	}
	// };

	// const editDriverTruck = async () => {
	// 	const url = api + "/driver/update/" + driverId;
	// 	const token = localStorage.getItem("token");
	// 	axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
	// 	const driverNewTruck = {
	// 		truck: {
	// 			_id: newTruck.value,
	// 			number: newTruck.label,
	// 		},
	// 	};
	// 	try {
	// 		await axios.patch(url, driverNewTruck);
	// 		getDrivers();
	// 	} catch (error) {
	// 		console.error(error.message);
	// 		return null;
	// 	}
	// };

	// delete load
	const deleteLoad = async (load) => {
		setLoadingTable(true);
		const url = api + `/load/${load.id}`;
		const urlHistory = api + `/history`;
		const urlJob = api + `/job/${job._id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const bodyHistory = {
				date: new Date(),
				customer: job.customer._id,
				user: localStorage.getItem("userId"),
				type: "Load",
				yards: load.yards,
				number: load.deliveryTicket,
			};
			await axios.post(urlHistory, bodyHistory);
			await axios.delete(url);
			getLoadData();

			const jobBody = {
				loadYD3: loadSum,
			};

			const currentSum = parseFloat(localStorage.getItem("loadSum"));
			const newSum = currentSum - load.yards;
			const result = Math.round(newSum * 100) / 100;
			localStorage.setItem("loadSum", result);

			await axios.patch(urlJob, jobBody);
			openNotification("success", "Success", "Load deleted");
			// pcb(-load.yards);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// Send SMS to company contact and foreman
	const sendSMS = async (values, phone) => {
		const url = api + "/smsmessagetxt";

		// Para el botón "Notify" en job appointment usar este texto:
		// Job id $number appointment is scheduled at $date $time
		// "notifyTimeStamp" (date) se guarda esa fecha al momento de dar clic,
		// mostrar este timestamp en otra columna.
		// Para el boton "Close" en job appointment onClick jobStatus = "ok" y ya no permitir
		// cambios. Nueva propiedad en job: "closeAppointment" (boolean)

		let msg = `Your order with ${values.yards} yards has been loaded. `;
		msg += `Ticket: ${values.deliveryTicket}. Driver: ${values.driver.label}.`;

		if (values.truck.label !== "0") {
			msg += " Truck No. " + values.truck.label;
		}

		msg += " #57concrete";

		const smsBody = {
			phone,
			msg,
		};

		try {
			await axios.post(url, smsBody);
		} catch (err) {
			setLoadingButton(false);
			openNotification("error", "Error", "Cannot send message");
			console.error(err.message);
			return null;
		}
	};

	// save new load
	const saveLoadData = async (values, sequenceNumber) => {
		setLoadingButton(true);
		const url = api + "/load";
		const urlJob = api + `/job/${job._id}`;
		const token = localStorage.getItem("token");
		const userId = localStorage.getItem("userId");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const loadDate = new Date();

		const carga = {
			yards: values.yards,
			driver: values.driver.label,
			driverId: values.driver.value,
			job: job._id,
			date: loadDate,
			deliveryTicket: values.deliveryTicket,
			orderCode: values.orderCode,
			truckNumber: values.truck.label,
			truck: values.truck.value,
			sequence: sequenceNumber,
			createdBy: userId,
			updatedBy: userId,
		};

		const currentSum = parseFloat(localStorage.getItem("loadSum"));
		setNewLoad(newLoad);
		const newSum = currentSum + parseFloat(newLoad);
		const result = Math.round(newSum * 100) / 100;

		localStorage.setItem("loadSum", result);

		try {
			await axios.post(url, carga);
			// setLoadChange((prev) => !prev);
			// pcb(parseFloat(newLoad));
			const job = {
				loadYD3: parseFloat(localStorage.getItem("loadSum")),
			};
			await axios.patch(urlJob, job);
			getLoadData();
			setLoadingButton(false);
			openNotification("success", "Success", "Load created");
		} catch (err) {
			openNotification("error", "Error", "Load cannot be created");

			setLoadingButton(false);
			console.error(err);
			return null;
		}
	};

	// const DrawLoad = () => {
	// 	if (loads.length > 0) {
	// 		return loads.map((ld) => {
	// 			const hyperlink = `http://ec2-3-85-198-38.compute-1.amazonaws.com/${ld._id}`;
	// 			let status = "In Plant";

	// 			let time = 0;
	// 			if (ld.job.destinationTime) {
	// 				time = parseInt(ld.job.destinationTime.split(" ")[0]);
	// 			} else if (ld.job.destinationMinutes) {
	// 				time = ld.job.destinationMinutes;
	// 			}

	// 			let minutes = 0;
	// 			if (ld.toJobDate && ld.toArriveDate) {
	// 				let toJobDate = new Date(ld.toJobDate);
	// 				let toArriveDate = new Date(ld.toArriveDate);
	// 				var difference = toArriveDate.getTime() - toJobDate.getTime(); // This will give difference in milliseconds
	// 				minutes = Math.round(difference / 60000);
	// 			}

	// 			let diff = time - minutes;
	// 			if (ld.statusTrip === "Stand By") {
	// 				status = "In Plant";
	// 			} else if (ld.statusTrip === "Start Trip") {
	// 				status = "In Progress";
	// 			} else {
	// 				status = "Arrived";
	// 			}

	// 			return (
	// 				<tr key={ld._id}>
	// 					<td>{ld.driver}</td>
	// 					<td>{ld.truckNumber} </td>
	// 					<td>{ld.yards}</td>
	// 					<td>
	// 						{new Intl.DateTimeFormat("en-US", optionsDate).format(
	// 							new Date(ld.date)
	// 						)}
	// 					</td>
	// 					{/* <td>{new Date(ld.date).toLocaleTimeString() }</td> */}
	// 					<td>{status}</td>
	// 					<td>{diff}</td>
	// 					<td>{ld.deliveryTicket}</td>
	// 					<td> {ld.orderCode} </td>
	// 					<td> {ld.sequence ? ld.sequence : "-"} </td>
	// 					{/* <td>
	// 						<p onClick={() => history.push(`/e-ticket/${ld._id}`)}>eticket</p>
	// 					</td> */}
	// 					<td>
	// 						<a href={hyperlink} target="_blank" className="btn btn-primary">
	// 							<FontAwesomeIcon icon={faFilePdf} />
	// 						</a>
	// 					</td>
	// 					<td>
	// 						<button
	// 							className="btn btn-danger"
	// 							onClick={() => deleteLoad(ld)}
	// 							disabled={
	// 								localStorage.getItem("email") === "vm@57concrete.com" ||
	// 								// localStorage.getItem('email') === "ji@57concrete.com" ||
	// 								localStorage.getItem("email") === "fc@57concrete.com" ||
	// 								localStorage.getItem("email") === "jp@57concrete.com" ||
	// 								localStorage.getItem("email") === "ec@57concrete.com" ||
	// 								localStorage.getItem("email") === "eliud@gmail.com"
	// 									? false
	// 									: true
	// 							}
	// 						>
	// 							-
	// 						</button>
	// 					</td>
	// 				</tr>
	// 			);
	// 		});
	// 	} else {
	// 		return (
	// 			<tr>
	// 				<td colSpan="9" style={{ textAlign: "center" }}>
	// 					No load yet
	// 				</td>
	// 			</tr>
	// 		);
	// 	}
	// };

	// const validateDriver = () => {
	// 	if (!driver) {
	// 		setDriverSelected(false);
	// 		return false;
	// 	} else {
	// 		setDriverSelected(true);
	// 		return true;
	// 	}
	// };

	/*
        Send SMS messages only to registered phones
    */
	const sendMessages = (values) => {
		if (job.customer.contactPhone) {
			sendSMS(values, job.customer.contactPhone);
		}
		if (job.foremanPhone) {
			sendSMS(values, job.foremanPhone);
		}
		if (job.pmPhone) {
			sendSMS(values, job.pmPhone);
		}
		if (job.supervisorPhone) {
			sendSMS(values, job.supervisorPhone);
		}
	};

	// check sequence
	const checkSequence = async (values) => {
		setLoadingButton(true);
		const startDate = new Date();

		const url =
			api +
			`/loadsequence?startDate=${formatDate(startDate)}&sequence=${
				values.sequence
			}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.post(url);

			if (data.loadId) {
				const body = {
					sequence: data.newSequence,
					updatedBy: localStorage.getItem("userId"),
				};
				updateLoad(data.loadId, body);
				saveLoadData(values, data.sequence);
				// editDriverTruck();
				sendMessages(values);
			} else {
				saveLoadData(values, data.sequence);
				// editDriverTruck();
				sendMessages(values);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			setLoadingButton(false);
			console.error(err.message);
			return null;
		}
	};

	// upload load sequence
	const updateLoad = async (loadId, body) => {
		const url = api + `/load/${loadId}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.patch(url, body);
		} catch (err) {
			openNotification("error", "Error", err.message);
			setLoadingButton(false);
			console.error(err.message);
			return null;
		}
	};

	// const onSubmit = (e) => {
	// 	e.preventDefault();

	// 	// checkSequence()

	// 	// if (validateDriver()) {
	// 	// saveLoadData()
	// 	// checkSequence();
	// 	// editDriverTruck()
	// 	// sendMessages()
	// 	// }
	// };

	const RenderTable = () => {
		return (
			<CustomTable
				columns={columns}
				data={loads}
				isScroll={false}
				loading={loadingTable}
			/>
		);
	};

	const onFinish = (values) => {
		checkSequence(values);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			style={{ marginTop: "20px" }}
			initialValues={{
				driver: undefined,
				truck: undefined,
				yards: undefined,
				deliveryTicket: undefined,
				orderCode: undefined,
				sequence: undefined,
			}}
		>
			<Row gutter={16}>
				<Col span={24} sm={12}>
					<Form.Item
						label="Driver"
						name="driver"
						rules={[
							{
								required: true,
								message: "Driver is required",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							dropdownStyle={{ zIndex: 2000 }}
							size="large"
							placeholder="Please select driver"
							options={optionsDrivers}
							loading={loadingDriver}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							labelInValue
						/>
					</Form.Item>
				</Col>
				<Col span={24} sm={12}>
					<Form.Item
						label="Truck"
						name="truck"
						rules={[
							{
								required: true,
								message: "Truck is required",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							dropdownStyle={{ zIndex: 2000 }}
							size="large"
							placeholder="Please select truck"
							options={optionsTrucks}
							loading={loadingTruck}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							labelInValue
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} sm={12}>
					<Form.Item
						label="Load"
						name="yards"
						rules={[
							{
								required: true,
								message: "Yards are required",
							},
						]}
					>
						<InputNumber
							step={0.1}
							size="large"
							placeholder="Enter yards"
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
				<Col span={24} sm={12}>
					<Form.Item
						label="Delivery Ticket"
						name="deliveryTicket"
						rules={[
							{
								required: true,
								message: "Delivery Ticket is required",
							},
						]}
					>
						<Input size="large" placeholder="Enter delivery ticket" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} sm={12}>
					<Form.Item
						label="Order Code"
						name="orderCode"
						rules={[
							{
								required: true,
								message: "Order code is required",
							},
						]}
					>
						<Input size="large" placeholder="Enter order code" />
					</Form.Item>
				</Col>
				<Col span={24} sm={12}>
					<Form.Item
						label="Sequence"
						name="sequence"
						rules={[
							{
								required: true,
								message: "Sequence is required",
							},
						]}
					>
						<InputNumber
							step={1}
							min={1}
							size="large"
							placeholder="Enter sequence"
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} sm={12} md={8} lg={4}>
					<Button
						type="primary"
						htmlType="submit"
						loading={loadingButton}
						size="large"
						style={{
							width: "100%",
						}}
						icon={<PlusCircleFilled twoToneColor={"white"} />}
					>
						Add
					</Button>
				</Col>
			</Row>
		</Form>
	);

	return (
		<div>
			{contextHolder}
			<Flex vertical gap={"middle"}>
				<Text strong style={{ fontSize: "20px" }}>
					Load Total: {Math.round(loadSum * 100) / 100}
				</Text>
				<RenderTable />
				{loading ? <CustomSpinner /> : <CustomForm />}
			</Flex>
			{/* <form className="form" onSubmit={onSubmit}>
				<div className="form-group row">
					<div className="col-sm-6">
						<DriverPicker action={onChangeDriver} />
						<br />
						<input
							type="number"
							id="load"
							value={newLoad}
							name="load"
							required
							className="form-control"
							placeholder="Enter load"
							step="0.1"
							onChange={(e) => setNewLoad(e.target.value)}
						/>
					</div>
					<div className="col-sm-6">
						<TruckPicker action={onChangeTruck} />
						<br />
						<input
							type="text"
							name="deliveryTicket"
							placeholder="Delivery Ticket"
							required
							value={deliveryTicket}
							className="form-control"
							onChange={(e) => setDeliveryTicket(e.target.value)}
						/>
					</div>
				</div>
				<br />
				<div className="form-group row">
					<div className="col-sm-6">
						<input
							type="text"
							name="orderCode"
							placeholder="Order Code"
							required
							value={orderCode}
							className="form-control"
							onChange={(e) => setOrderCode(e.target.value)}
						/>
					</div>
					<div className="col-sm-6">
						<input
							type="number"
							min={1}
							name="sequence"
							placeholder="Sequence"
							required
							value={sequence}
							className="form-control"
							onChange={(e) => setSequence(e.target.value)}
						/>
					</div>
					<div className="col-sm-6">
						<button type="submit" className="btn btn-success">
							+
						</button>
					</div>
				</div>
			</form> */}
			{/* {!driverSelected && <div>You must select a driver</div>} */}
		</div>
	);
};

export default LoadJob;
