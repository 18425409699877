import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
	notification,
	InputNumber,
	DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import AddressGeolocation2 from "../map/AddressGeolocation2";
import {
	jobPourOptions,
	jobTypeOptions,
	slumpOptions,
} from "../../constants/Job";
import { cityList } from "../utils/CityList";
import { paymentTypeOptions } from "../../constants/Customer";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";
const { Option } = Select;

const OpportunityNegotiation = ({ id, callback }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [openSearchJobAddressModal, setOpenSearchJobAddressModal] =
		useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [optionsPsi, setOptionsPsi] = useState([]);
	const [optionsPlant, setOptionsPlant] = useState([]);
	const [optionsForemans, setOptionsForemans] = useState([]);
	const [loadingPsi, setLoadingPsi] = useState(true);
	const [loadingPlant, setLoadingPlant] = useState(true);
	const [allowSearchAddress, setAllowSearchAddress] = useState(true);
	const [disabledAddress, setDisabledAddress] = useState(true);
	const [loadingForeman, setLoadingForeman] = useState(true);
	const [dateMax, setDateMax] = useState(dayjs());
	const [status, setStatus] = useState("");

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getInitialValuesArrays();
	}, []);

	const getInitialValuesArrays = async () => {
		await Promise.all([getPlants(), getPsi(), getForeman()]);
		getOpportunity();
	};

	const getOpportunity = async () => {
		try {
			const url = api + "/opportunity/get/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.get(url);
			if (data.success) {
				const opportunity = data.payload;
				console.log(opportunity);
				setInitialValues({
					prefix: "+1",
					city: opportunity.shipCity
						? opportunity.shipCity.toLowerCase()
						: undefined,
					destinationTime: opportunity.destinationTime || undefined,
					distance: opportunity.distance || undefined,
					jobPour: opportunity.jobPoor || undefined,
					jobType: opportunity.jobType || undefined,
					plant: opportunity.plant || undefined,
					psi: opportunity.psi || undefined,
					psiId: opportunity.psiId || undefined,
					targetYD3: opportunity.targetYD3 || undefined,
					jobLatitude: opportunity.jobLatitude || undefined,
					jobLongitude: opportunity.jobLongitude || undefined,
					shipAddress: opportunity.shipAddress || undefined,
					shipCity: opportunity.shipCity || undefined,
					unitPrice: opportunity.unitPrice || undefined,
					foremanPhone: opportunity.foremanPhone
						? opportunity.foremanPhone.substring(2)
						: undefined,
					jobForeman: opportunity.jobForeman || undefined,
					foreman: opportunity.foreman || undefined,
					foremanItem:
						opportunity.foreman &&
						opportunity.jobForeman &&
						opportunity.foremanPhone
							? `${opportunity.foreman},${opportunity.jobForeman},${opportunity.foremanPhone}`
							: undefined,
					jobApproved: opportunity.jobApproved,
					paymentType: opportunity.paymentType || undefined,
					scheduledDate: opportunity.scheduledDate
						? dayjs(opportunity.scheduledDate)
						: dayjs(),
					inspectionTimeStamp: opportunity.inspectionTimeStamp
						? dayjs(opportunity.inspectionTimeStamp)
						: dayjs(),
					taxPrice: opportunity.unitPrice
						? opportunity.unitPrice * 1.0825
						: undefined,
					slump: opportunity.slump || undefined,
					comments: opportunity.comments || undefined,
				});
				setStatus(opportunity.status);

				setAllowSearchAddress(
					opportunity.jobLatitude === undefined &&
						opportunity.jobLongitude === undefined
				);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to get opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const updateOpportunity = async (data) => {
		const opportunity = {
			...data,
			jobPoor: data.jobPour,
		};

		if (data.foremanPhone) opportunity.foremanPhone = "+1" + data.foremanPhone;
		opportunity.scheduledDate = data.scheduledDate.toDate();
		opportunity.inspectionTimeStamp = data.inspectionTimeStamp.toDate();
		delete opportunity.prefix;
		delete opportunity.jobPour;
		delete opportunity.city;
		delete opportunity.foremanItem;
		delete opportunity.taxPrice;

		try {
			const url = api + "/opportunity/update/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				openNotification("success", "Success", data.message);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const updateOpportunityStatus = async (newStatus) => {
		setLoading(true);
		const opportunity = {
			status: newStatus,
		};

		try {
			const url = api + "/opportunity/update/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				const payload = data.payload;

				openNotification("success", "Success", data.message);

				setTimeout(() => {
					callback(payload.status);
					setLoading(false);
				}, 2000);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const onChangeLostOpportunity = () => {
		updateOpportunityStatus("Lost");
	};

	const onChangeClosedOpportunity = () => {
		updateOpportunityStatus("Closed");
	};

	const getPlants = async () => {
		const url = api + "/plant";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsPlant(list);
			setLoadingPlant(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getPsi = async () => {
		const url = api + "/psi";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: `${item._id},${item.price}`,
					label: item.psi,
				});
			});
			setOptionsPsi(list);
			setLoadingPsi(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getForeman = async () => {
		const url = api + `/foreman`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			const optionsAux = [];
			data.forEach((foreman) => {
				let value = `${foreman._id},${foreman.firstName} ${foreman.lastName},${foreman.phone}`;
				let aux = `${foreman.firstName} ${foreman.lastName}`;
				let label = aux.toUpperCase();
				let obj = {
					value: value,
					label: label,
				};
				if (foreman.active && foreman.active === true) optionsAux.push(obj);
			});
			setOptionsForemans(optionsAux);
			setLoadingForeman(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const onChangePsi = (selectedPsi) => {
		if (selectedPsi) {
			let values = selectedPsi.value.split(",");
			form.setFieldsValue({
				// psiPrice: values[1],
				unitPrice: values[1],
				psi: selectedPsi.label,
				psiId: values[0],
				taxPrice: values[1] * 1.0825,
			});
			// setPsiContractPrice(selectedPsi);
		}
	};

	const onChangePlant = (selectedPlant) => {
		if (selectedPlant) setAllowSearchAddress(false);
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const onFinish = () => {
		setLoading(true);
		const values = form.getFieldsValue(true);

		if (dayjs(values.inspectionTimeStamp).isSame(dayjs(values.scheduledDate))) {
			openNotification(
				"warning",
				"Warning",
				"Inspection and Scheduled date must be different"
			);
			setLoading(false);
		} else {
			updateOpportunity(values);
		}
	};

	const handleSearchJobAddressCancel = () => {
		setOpenSearchJobAddressModal(false);
	};

	const handleSearchJobAddressOpen = () => {
		setOpenSearchJobAddressModal(true);
	};

	const handleCallbackJob = (childData) => {
		form.setFieldsValue({
			shipAddress: childData.address,
			destinationTime: childData.duration,
			distance: childData.distance,
			jobLatitude: childData.destLat,
			jobLongitude: childData.destLng,
			shipCity: childData.locality,
		});
		handleSearchJobAddressCancel();
		setDisabledAddress(false);

		let cityFound = cityList.find((city) => city.label === childData.locality);
		if (typeof cityFound !== "undefined") {
			form.setFieldValue("city", childData.locality.toLowerCase());
		} else {
			openNotification(
				"warning",
				"Warning",
				`The city ${childData.locality} is not defined in list, please report to IT team`
			);
		}
	};

	const onChangeDate = (value) => {
		setDateMax(value);
		form.setFieldValue("inspectionTimeStamp", value);
	};

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const disabledDateTime = () => {
		const scheduledDateMinute = dayjs(dateMax).minute() + 1;
		const scheduledDateHour = dayjs(dateMax).hour() + 1;
		return {
			disabledMinutes: () => range(scheduledDateMinute, 60),
			disabledHours: () => range(scheduledDateHour, 24),
		};
	};

	const RenderSearchJobAddressModal = () => {
		return (
			<Modal
				title="Address"
				open={openSearchJobAddressModal}
				onCancel={handleSearchJobAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddressGeolocation2
					search={!allowSearchAddress}
					parentCallback={handleCallbackJob}
					single={false}
					plant={form.getFieldValue("plant")}
					currentLocation={false}
				/>
			</Modal>
		);
	};

	const onChangeCity = (selectedCity) => {
		if (selectedCity) {
			form.setFieldValue("shipCity", selectedCity.label);
		}
	};

	const onChangeForeman = (selectedForeman) => {
		if (selectedForeman) {
			let option = selectedForeman.split(",");
			let foremanID = option[0];
			let foremanName = option[1];
			let foremanPhone = option[2];
			form.setFieldsValue({
				foremanPhone: foremanPhone.slice(2),
				jobForeman: foremanName,
				foreman: foremanID,
			});
		}
	};

	const onChangePaymentType = (selectedPaymentType) => {
		if (selectedPaymentType) {
			form.setFieldValue("jobApproved", true);
		}
	};

	const onChangeUnitPrice = (value) => {
		if (value >= 0 && value <= 2000) {
			form.setFieldValue("taxPrice", (value * 1.0825).toFixed(3));
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Job Type"
							name="jobType"
							rules={[
								{
									required: true,
									message: "Please select a job type",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select job type"
								options={jobTypeOptions}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Job Pour"
							name="jobPour"
							rules={[
								{
									required: true,
									message: "Please select a job pour",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select job pour"
								options={jobPourOptions}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Psi"
							name="psi"
							rules={[
								{
									required: true,
									message: "Please select a psi",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a psi"
								options={optionsPsi}
								disabled={loadingPsi}
								loading={loadingPsi}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								labelInValue
								onChange={onChangePsi}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Plant"
							name="plant"
							rules={[
								{
									required: true,
									message: "Please select a plant",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a plant"
								options={optionsPlant}
								disabled={loadingPlant}
								loading={loadingPlant}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangePlant}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} lg={8} xl={6} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<SearchOutlined />}
							onClick={handleSearchJobAddressOpen}
							disabled={allowSearchAddress}
							style={{ width: "100%" }}
						>
							Search Address
						</Button>
					</Col>
					<Col span={24} lg={16} xl={18}>
						<Form.Item
							label="Ship Address"
							name="shipAddress"
							rules={[
								{
									required: true,
									message: "Fill ship address",
								},
							]}
						>
							<Input size="large" disabled={disabledAddress} />
						</Form.Item>
					</Col>
					<Col span={24} sm={8}>
						<Form.Item
							label="City"
							name="city"
							rules={[
								{
									required: true,
									message: "Please select a city",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a city"
								options={cityList}
								// disabled={loadingComponents}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangeCity}
								labelInValue
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={8}>
						<Form.Item label="Time to Destination" name="destinationTime">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col span={24} sm={8}>
						<Form.Item label="Distance" name="distance">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Foreman"
							name="foremanItem"
							rules={[
								{
									required: true,
									message: "Please select a foreman",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select foreman"
								options={optionsForemans}
								disabled={loadingForeman}
								loading={loadingForeman}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangeForeman}
								labelInValue
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Foreman Phone" name="foremanPhone">
							<Input size="large" addonBefore={prefixSelector} disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Payment Type"
							name="paymentType"
							rules={[
								{
									required: true,
									message: "Please select a payment type",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select payment type"
								options={paymentTypeOptions}
								optionFilterProp="children"
								onChange={onChangePaymentType}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Target YD3"
							name="targetYD3"
							rules={[
								{
									required: true,
									message: "Please fill target",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item name="scheduledDate" label="Job date/time">
							<DatePicker
								size="large"
								showTime={{ format: "HH:mm" }}
								format="MM/DD/YYYY HH:mm A"
								allowClear={false}
								onChange={onChangeDate}
								style={{ width: "100%" }}
								// disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item name="inspectionTimeStamp" label="Inspection date/time">
							<DatePicker
								size="large"
								showTime={{ format: "HH:mm" }}
								format="MM/DD/YYYY HH:mm A"
								allowClear={false}
								maxDate={dateMax}
								disabledTime={disabledDateTime}
								style={{ width: "100%" }}
								// disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Unit Price"
							name="unitPrice"
							rules={[
								{
									required: true,
									message: "Please fill unit price",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								onChange={onChangeUnitPrice}
								precision={3}
								// disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Price with Tax" name="taxPrice">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled
								precision={3}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Slump"
							name="slump"
							rules={[
								{
									required: true,
									message: "Please select a slump",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select slump"
								options={slumpOptions}
								optionFilterProp="children"
								// disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Comments" name="comments">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							size="large"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
						>
							Save
						</Button>
					</Col>
					<Col span={12} sm={12} md={7} lg={5}>
						<Button
							type="primary"
							danger
							htmlType="button"
							loading={loading}
							size="large"
							style={{
								width: "100%",
								marginBottom: "10px",
								backgroundColor: "green",
							}}
							onClick={onChangeClosedOpportunity}
							disabled={status === "Lost" || status === "Closed"}
						>
							Close Opportunity
						</Button>
					</Col>
					<Col span={12} sm={12} md={7} lg={5}>
						<Button
							type="primary"
							danger
							htmlType="button"
							loading={loading}
							size="large"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
							onClick={onChangeLostOpportunity}
							disabled={status === "Lost" || status === "Closed"}
						>
							Lost Opportunity
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<div>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
			<RenderSearchJobAddressModal />
		</div>
	);
};

export default OpportunityNegotiation;
