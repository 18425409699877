import { Button, notification, Steps } from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import OpportunityNew from "./OpportunityNew";
import OpportunityContact from "./OpportunityContact";
import OpportunityNegotiation from "./OpportunityNegotiation";

const isAdminUser =
	localStorage.getItem("email") === "eliud@gmail.com" ||
	localStorage.getItem("email") === "ec@57concrete.com" ||
	localStorage.getItem("email") === "bp@57concrete.com" ||
	localStorage.getItem("email") === "mgr@57concrete.com" ||
	localStorage.getItem("email") === "rl@57concrete.com";

const Opportunity = ({ history, match }) => {
	const [apiNotification, contextHolder] = notification.useNotification();
	const [status, setStatus] = useState("New");
	const [current, setCurrent] = useState(0);

	const onChangeStepChild = (newStatus) => {
		setStatus(newStatus);
		setCurrent(steps.findIndex((step) => step.title === newStatus));
	};

	const steps = [
		{
			title: "New",
			content: (
				<OpportunityNew
					id={match.params.id}
					callback={onChangeStepChild}
					type={"new"}
				/>
			),
			disabled: !isAdminUser,
		},
		{
			title: "Localize",
			content: (
				<OpportunityNew
					id={match.params.id}
					callback={onChangeStepChild}
					type={"localize"}
				/>
			),
		},
		{
			title: "Contact",
			content: (
				<OpportunityContact id={match.params.id} callback={onChangeStepChild} />
			),
			disabled: status === "Localize" || !isAdminUser,
		},
		{
			title: "Negotiation",
			content: (
				<OpportunityNegotiation
					id={match.params.id}
					callback={onChangeStepChild}
				/>
			),
			disabled: status === "Contact" || status === "Localize" || !isAdminUser,
		},
		{
			title: "Lost",
			content: (
				<div className="text-center">
					<p
						style={{
							textAlign: "center",
							paddingTop: "20px",
							fontWeight: "bold",
						}}
					>
						This opportunity has been lost during process.
					</p>
					<Button
						type="primary"
						onClick={() => history.push("/opportunity/dashboard")}
					>
						Go back to dashboard
					</Button>
				</div>
			),
			disabled:
				status === "Contact" ||
				status === "Localize" ||
				status === "Negotiation" ||
				!isAdminUser ||
				status === "Closed",
		},
		{
			title: "Closed",
			content: (
				<div className="text-center">
					<p
						style={{
							textAlign: "center",
							paddingTop: "20px",
							fontWeight: "bold",
						}}
					>
						This opportunity has been closed succesfully.
					</p>
					<Button
						type="primary"
						onClick={() => history.push("/opportunity/dashboard")}
					>
						Go back to dashboard
					</Button>
				</div>
			),
			disabled:
				status === "Contact" ||
				status === "Localize" ||
				status === "Negotiation" ||
				!isAdminUser ||
				status === "Lost",
		},
	];
	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getOpportunity();
	}, []);

	const getOpportunity = async () => {
		try {
			const url = api + "/opportunity/get/" + match.params.id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.get(url);
			if (data.success) {
				const opportunity = data.payload;
				setStatus(opportunity.status);
				setCurrent(
					steps.findIndex((step) => step.title === opportunity.status)
				);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Error while trying to get opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const items = steps.map((item, index) => ({
		key: index,
		title: item.title,
		disabled: item.disabled,
	}));

	const onChangeStep = (step) => {
		setCurrent(step);
	};

	// const StepperContainer = () => {
	// 	return (
	// 		<div>
	// 			<Steps items={steps} />
	// 			<div>{steps[current].content}</div>
	// 		</div>
	// 	);
	// };

	return (
		<Layout title="Opportunity" description="Opportunity stepper" type="medium">
			{contextHolder}
			{/* <StepperContainer /> */}
			<Steps
				type="navigation"
				current={current}
				items={items}
				onChange={onChangeStep}
			/>
			<div>{steps[current].content}</div>
		</Layout>
	);
};

export default Opportunity;
