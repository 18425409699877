import React, { Component } from "react";
import Select from "react-select";

export default class PriorityPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isClearable: false,
			isSearchable: false,
			priorityList: [
				{ value: "Low", label: "Low" },
				{ value: "Medium", label: "Medium" },
				{ value: "High", label: "High" },
			],
		};
	}

	render() {
		const options = this.state.priorityList;

		return (
			<div style={{ fontSize: "10px" }}>
				<Select
					name="form-field-name"
					placeholder="Job Priority..."
					value={options.filter((o) => {
						return o.label === this.props.value;
					})}
					onChange={this.props.action}
					isClearable={this.state.isClearable}
					isSearchable={this.state.isSearchable}
					options={options}
				/>
			</div>
		);
	}
}
