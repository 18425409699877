import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Table,
	Statistic,
	Row,
	Col,
	Button,
	notification,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
const { Text } = Typography;

const columnsCSV = [
	{ key: "date", label: "Date" },
	{ key: "owner", label: "Owner" },
	{ key: "lender", label: "Lender" },
	{ key: "collateral", label: "Collateral" },
	{ key: "totalPayment", label: "Total Amount" },
];

const columnsCSV2 = [
	{ key: "date", label: "Date" },
	{ key: "invoice", label: "invoice" },
	{ key: "owner", label: "Owner" },
	{ key: "lender", label: "Lender" },
	{ key: "collateral", label: "Collateral" },
	{ key: "payment", label: "Total Amount" },
];

const LoanPaymentsReport = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [list, setList] = useState([]);
	const [listCSV, setListCSV] = useState([]);
	const refDownload = useRef();
	const refDownload2 = useRef();
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "owner",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Lender",
			dataIndex: "lender",
			key: "lender",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Collateral",
			dataIndex: "collateral",
			key: "collateral",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Payment Amount",
			dataIndex: "totalPayment",
			key: "totalPayment",
			render: (value) => {
				return (
					<Statistic
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
	];

	const expandedRowRender = (row) => {
		const columns = [
			{
				title: "Date",
				dataIndex: "date",
				key: "date",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Invoice",
				dataIndex: "invoice",
				key: "invoice",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Owner",
				dataIndex: "owner",
				key: "owner",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Lender",
				dataIndex: "lender",
				key: "lender",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Collateral",
				dataIndex: "collateral",
				key: "collateral",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Payment Amount",
				dataIndex: "payment",
				key: "payment",
				render: (value) => {
					return (
						<Statistic
							value={value}
							prefix={"$"}
							precision={2}
							valueStyle={{ fontSize: 14 }}
						/>
					);
				},
			},
		];

		let trips = row.payments.map((item, index) => {
			item.key = index;
			item.date = Intl.DateTimeFormat("en-US").format(
				new Date(item.paymentDate)
			);
			item.lender = row.lender;
			item.owner = row.owner;
			item.collateral = row.collateral;
			return item;
		});

		return (
			<Table
				columns={columns}
				dataSource={trips}
				pagination={false}
				summary={(pageData) => {
					const total = pageData.reduce((accum, val) => {
						return accum + val.payment;
					}, 0);
					return (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}></Table.Summary.Cell>
								<Table.Summary.Cell index={1}></Table.Summary.Cell>
								<Table.Summary.Cell index={2}></Table.Summary.Cell>
								<Table.Summary.Cell index={3}></Table.Summary.Cell>
								<Table.Summary.Cell index={4} align="right">
									<Text style={{ fontSize: 14 }} strong>
										{"Total"}
									</Text>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={5}>
									<Statistic
										value={total}
										prefix={"$"}
										precision={2}
										valueStyle={{ fontSize: 14 }}
									/>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					);
				}}
			/>
		);
	};

	useEffect(() => {
		getPayments("");
	}, [startDate, endDate]);

	const getPayments = async (term) => {
		setLoading(true);
		setListCSV([]);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url =
			api +
			`/loan/get/payments/filtered?startDate=${start}&endDate=${end}&term=${term}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			let listRows = [];

			if (data.success) {
				let filterdData = data.payload.filter(
					(val) => val.payments.length !== 0
				);
				filterdData.map((item, index) => {
					const sumPayment = item.payments.reduce((accum, value) => {
						return accum + value.payment;
					}, 0);
					list.push({
						key: index,
						id: item._id,
						date: Intl.DateTimeFormat("en-US").format(
							new Date(item.payments[0].paymentDate)
						),
						payments: item.payments,
						lender: item.lender,
						collateral: item.collateral,
						owner: item.owner,
						totalPayment: sumPayment,
					});
					item.payments.map((payment) => {
						listRows.push({
							date: Intl.DateTimeFormat("en-US").format(
								new Date(payment.paymentDate)
							),
							currentDate: payment.paymentDate,
							invoice: payment.invoice || "",
							lender: item.lender,
							collateral: item.collateral,
							owner: item.owner,
							payment: payment.payment,
						});
					});
				});
				listRows = listRows.sort((a, b) => new Date(a.date) - new Date(b.date));
				list = list.sort((a, b) => new Date(a.date) - new Date(b.date));
				setList(list);
				setListCSV(listRows);
				setLoading(false);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Table
					columns={columns}
					dataSource={list}
					expandable={{
						expandedRowRender,
					}}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100],
						defaultPageSize: 100,
						showSizeChanger: true,
					}}
					scroll={{
						x: true,
					}}
					style={{
						minWidth: "100%",
					}}
					summary={(pageData) => {
						const total = pageData.reduce((accum, val) => {
							return accum + val.totalPayment;
						}, 0);
						return (
							<Table.Summary fixed>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0}></Table.Summary.Cell>
									<Table.Summary.Cell index={1}></Table.Summary.Cell>
									<Table.Summary.Cell index={2}></Table.Summary.Cell>
									<Table.Summary.Cell index={3}></Table.Summary.Cell>
									<Table.Summary.Cell index={4} align="right">
										<Text style={{ fontSize: 14 }} strong>
											{"Total"}
										</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={5}>
										<Statistic
											value={total}
											prefix={"$"}
											precision={2}
											valueStyle={{ fontSize: 14 }}
										/>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							</Table.Summary>
						);
					}}
				/>
			</Flex>
		);
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getPayments(value);
		}
	};

	const RenderDates = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				dateFormat={"MM/DD/YYYY"}
				onRangeChange={onRangeChange}
				onSearch={onSearch}
			/>
		);
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={list}
					headers={columnsCSV}
					filename={"57concrete-loan-payments.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<CSVLink
					data={listCSV}
					headers={columnsCSV2}
					filename={"57concrete-loan-payments-detail.csv"}
					hidden
					ref={refDownload2}
				>
					Download report detailed
				</CSVLink>
				<Row gutter={16}>
					<Col span={24} xs={12}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report
						</Button>
					</Col>
					<Col span={24} xs={12}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload2.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report Detailed
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout
			title="Loan Payments Report"
			description="Loan Report"
			type="medium"
		>
			<Flex vertical gap={"large"}>
				<RenderDates />
				<RenderDownloadReport />
				{loading ? <CustomSpinner /> : <RenderTable />}
				{contextHolder}
			</Flex>
		</Layout>
	);
};

export default LoanPaymentsReport;
