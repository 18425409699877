import React, { Component } from "react";
import axios from "axios";
import Layout from "../layout/Layout";
import SellerPicker from "../seller/SellerPicker";
import DatePicker from "react-datepicker";
import SlumpPicker from "../slump/SlumpPicker";
import JobTypePicker from "./JobTypePicker";
import JobPoorPicker from "./JobPoorPicker";
import ClientPicker from "../client/ClientPicker";
import { toCurrency } from "../utils/Convert";
import JobstatusPicker from "./JobStatusPicker";
import PsiPicker from "../psi/PsiPicker";
import "react-datepicker/dist/react-datepicker.css";
import AddressGeolocation from "../map/AddressGeolocation";
import Modal from "react-bootstrap/Modal";
import PaymentTypePicker from "../paymenttype/PaymentTypePicker";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { api } from "../utils/Api";
import ForemanPicker from "../foreman/ForemanPicker";
import ClientModal from "../client/ClientModal";
import ReadLoad from "./ReadLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { cityList } from "../utils/CityList";
import Select from "react-select";
import SpinnerButton from "../layout/SpinnerButton";

export default class Job extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customerName: "",
			customerNumber: "",
			customer: "",
			customerId: "",
			sellerName: "",
			name: "",
			loadYD3: "",
			targetYD3: "",
			balance: "",
			jobDescription: "",
			jobStatus: "",
			shipAddress: "",
			shipCity: "",
			shipState: "TX",
			shipZip: "",
			jobForeman: "",
			foremanPhone: "",
			apContact: "",
			apEmail: "",
			apPhone: "",
			scheduledDate: "",
			dateChanged: false,
			scheduledTime: "",
			destinationMinutes: "",
			taxExemptJob: false,
			comments: "",
			driver: "",
			po: "",
			jobDuration: "",
			unitPrice: "",
			taxPrice: 0,
			slump: "",
			jobPoor: "",
			jobType: "",
			number: 0,
			date: new Date(),
			paid: false,
			isPhoneQuote: false,
			seller: "",
			psi: "",
			destinationTime: "",
			distance: "",
			latitude: "",
			longitude: "",
			searchAddress: false,
			paymentType: "",
			approved: false,
			inspectionTimeStamp: new Date(),
			hotShot: false,
			supervisorPhone: "",
			pmPhone: "",
			supervisorName: "",
			projectManager: "",
			foreman: "",
			clientModal: false,
			fuelSurcharge: 25,
			showLoad: false,
			loads: [],
			shippingFee: 300,
			fuelSurchargeGlobal: false,
			shippingGlobal: true,
			disabledDate: false,
			selectedOption: "",
			plant: "",
			loading: true,
			logisticDate: new Date(),
			sellerPhone: "",
			qualityControl: false,
			jobStatusPrev: "",
			jobStatusHistory: [],
			jobStatus2: "",
			fiberQuantity: 0,
			readyToBeScheduledDate: null,
			minDateScheduledDate: new Date(),
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	inputChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === "unitPrice") {
			this.setState({
				taxPrice: e.target.value * 1.0825,
			});
		}

		// if (e.target.name === 'shipAddress') {
		//     this.setState({
		//         latitude: null,
		//         longitude: null,
		//         destinationTime: null,
		//         distance: 0
		//     })
		// }
	};

	unitPriceInput = (e) => {
		let value = e.target.value;
		if (value >= 0 && value <= 2000) {
			this.setState({ [e.target.name]: value });
			this.setState({
				taxPrice: value * 1.0825,
			});
		}
	};

	onChangeDate = (date) => {
		this.setState({
			date,
			dateChanged: true,
			scheduledDate: date,
			jobStatus: "Date moved",
			logisticDate: null,
		});
	};

	sendSMSTextToSeller = async (date) => {
		const url = api + "/sellersms/";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		let text = `57Concrete: Date of Job No. ${
			this.state.number
		} has been moved to ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
		const body = {
			text: text,
			phone: this.state.sellerPhone,
		};

		try {
			await axios.post(url, body);
		} catch (err) {
			console.error(err.message);
		}
	};

	onChangeShowLoad = (boolean) => {
		this.setState({
			showLoad: boolean,
		});
	};
	onChangeSlump = (selectedSlump) => {
		if (selectedSlump) {
			this.setState({
				slump: selectedSlump.value,
			});
		}
	};

	// jobPoor option
	onChangeJobPoor = (selectedPoor) => {
		if (selectedPoor) {
			this.setState({
				jobPoor: selectedPoor.label,
			});
		}
	};

	// change PSI
	onChangePsi = (selectedPsi) => {
		if (selectedPsi) {
			this.setState({
				psi: selectedPsi.label,
				unitPrice: selectedPsi.value,
				taxPrice: selectedPsi.value * 1.0825,
			});
		}
	};

	// payment type
	onChangePaymentType = (selectedPayment) => {
		if (selectedPayment) {
			this.setState({
				paymentType: selectedPayment.label,
			});
		}
	};

	// job type option
	onChangeJobType = (selectedType) => {
		if (selectedType) {
			this.setState({
				jobType: selectedType.label,
			});
		}
	};

	// customer select
	onChangeCustomer = (selectedCustomer) => {
		if (selectedCustomer) {
			this.setState({
				customer: selectedCustomer.value,
			});
		}
	};

	// change job status
	onChangeJobStatus = (selectedOption) => {
		if (selectedOption) {
			this.setState({
				jobStatus: selectedOption.value,
				jobStatus2: selectedOption.label,
				approved: true,
			});
			if (selectedOption.value === "Ready to Load") {
				this.setState({
					readyToBeScheduledDate: new Date(),
				});
			}
			this.getDatePermissions(selectedOption.value);
		}
	};

	// new seller asignation
	onChangeSeller = (selectedOption) => {
		if (selectedOption) {
			this.setState({
				seller: selectedOption.value,
				sellerName: selectedOption.label,
			});
		}
	};

	// change paid
	onPaidChange = (e) => {
		this.setState({
			paid: !this.state.paid,
		});
	};

	// change quality control
	onQualityControlChange = (e) => {
		this.setState({
			qualityControl: !this.state.qualityControl,
		});
	};

	// show/hide search modal
	onShowModal = (e) => {
		this.setState({
			searchAddress: !this.state.searchAddress,
		});
	};

	// foreman phone
	onChangeForemanPhone = (e) => {
		this.setState({
			foremanPhone: e,
		});
	};

	// Foreman picker on change
	onChangeForeman = (selectedForeman) => {
		if (selectedForeman) {
			let option = selectedForeman.value.split(",");
			let foremanID = option[0];
			let foremanName = option[1];
			let foremanPhone = option[2];
			this.setState({
				foremanPhone: foremanPhone,
				jobForeman: foremanName,
				foreman: foremanID,
			});
		}
	};

	//Show/hide client modal
	onShowNewClient = (e) => {
		this.setState({
			clientModal: !this.state.clientModal,
		});
	};

	onNewClientNew = (nuevo) => {
		this.setState({
			//Cambios a datos para mostrar
			clientModal: false,
			customerName: nuevo.name,
			customerNumber: nuevo.customerNumber,
			customer: nuevo._id,
		});
	};

	onChangeSupervisorPhone = (e) => {
		this.setState({
			supervisorPhone: e,
		});
	};

	onChangePMPhone = (e) => {
		this.setState({
			pmPhone: e,
		});
	};

	onChangeInspectionDate = (date) => {
		this.setState({
			inspectionTimeStamp: date,
		});
	};

	setSelected = (selected) => {
		this.setState({
			shipCity: selected.label,
			selectedOption: selected,
		});
	};

	// address from modal
	handleCallback = (childData) => {
		this.setState({
			shipAddress: childData.address,
			destinationTime: childData.duration,
			distance: childData.distance,
			latitude: childData.destLat,
			longitude: childData.destLng,
			searchAddress: false,
			shipCity: childData.locality,
		});
		let cityFound = cityList.find((city) => city.label === childData.locality);
		if (typeof cityFound !== "undefined") {
			this.setSelected({
				value: childData.locality.toLowerCase(),
				label: childData.locality,
			});
		} else {
			alert(
				`The city ${childData.locality} is not defined in list, please report to IT team`
			);
		}
	};

	updateJob = async (fromButton) => {
		console.log(this.state.comments);
		let job = {
			name: this.state.name,
			jobDescription: this.state.jobDescription,
			shipAddress: this.state.shipAddress,
			jobForeman: this.state.jobForeman,
			foremanPhone: this.state.foremanPhone,
			apContact: this.state.apContact,
			apPhone: this.state.apPhone,
			contractor: this.state.contractor,
			contractorContact: this.state.contractorContact,
			contractorAddress: this.state.contractorAddress,
			contractorCity: this.state.contractorCity,
			contractorPhone: this.state.contractorPhone,
			contractorState: this.state.contractorState,
			contractorType: this.state.contractorType,
			contractorZip: this.state.contractorZip,
			loadYD3: this.state.loadYD3,
			targetYD3: this.state.targetYD3,
			balance: this.state.balance,
			jobStatus: this.state.jobStatus,
			scheduledDate: this.state.scheduledDate,
			inspectionTimeStamp: this.state.inspectionTimeStamp,
			slump: this.state.slump,
			jobType: this.state.jobType,
			jobPoor: this.state.jobPoor,
			unitPrice: this.state.unitPrice,
			paid: this.state.paid,
			seller: this.state.seller,
			psi: this.state.psi,
			destinationTime: this.state.destinationTime,
			distance: this.state.distance,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			shipCity: this.state.shipCity,
			paymentType: this.state.paymentType,
			approved: this.state.approved,
			updatedBy: localStorage.getItem("userId"),
			supervisorPhone: this.state.supervisorPhone,
			pmPhone: this.state.pmPhone,
			supervisorName: this.state.supervisorName,
			projectManager: this.state.projectManager,
			fuelSurcharge: this.state.fuelSurcharge,
			deliveryFee: this.state.shippingFee,
			fuelSurchargeGlobal: this.state.fuelSurchargeGlobal,
			shippingGlobal: this.state.shippingGlobal,
			logisticDate: this.state.logisticDate,
			qualityControl: this.state.qualityControl,
			fiberQuantity: this.state.fiberQuantity || 0,
			comments: this.state.comments,
		};

		if (this.state.customer) {
			job.customer = this.state.customer;
		}

		if (this.state.jobStatus !== this.state.jobStatusPrev) {
			job.jobStatusHistory = {
				user: localStorage.getItem("userId"),
				jobStatus: this.state.jobStatus,
			};
		}

		if (this.state.jobStatus === "Ready to Load") {
			if (this.state.jobStatus !== this.state.jobStatusPrev) {
				job.readyToBeScheduledDate = this.state.readyToBeScheduledDate;
			}
		}

		const url = api + "/job/" + this.props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.patch(url, job);
			this.setState({
				loading: false,
			});
			if (this.state.dateChanged) {
				this.sendSMSTextToSeller(this.state.scheduledDate);
			}
			if (fromButton === "save") {
				this.props.history.push("/dailyappointment");
			}
		} catch (err) {
			this.setState({
				loading: false,
			});
			console.error(err.message);
			return null;
		}
	};

	validatePsi = () => {
		if (this.state.psi) {
			return true;
		} else {
			alert("PSI must be selected");
			return false;
		}
	};

	validateSeller = () => {
		if (this.state.seller) {
			return true;
		} else {
			alert("seller must be selected");
			return false;
		}
	};

	validateAttributeDefinition = (attribute, msg) => {
		if (attribute) {
			return true;
		} else {
			alert(msg);
			return false;
		}
	};

	onSubmit(e) {
		e.preventDefault();
		if (
			this.validateAttributeDefinition(
				this.state.seller,
				"Seller must be selected"
			) &&
			this.validateAttributeDefinition(
				this.state.psi,
				"PSI must be selected"
			) &&
			this.validateAttributeDefinition(
				this.state.shipAddress,
				"You need to define ship address"
			)
		) {
			this.setState({
				loading: true,
			});
			this.updateJob("save");
		}
	}

	getJob = async () => {
		const url = api + "/job/" + this.props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			this.setState({
				customerName: data.customer.name,
				customerNumber: data.customer.customerNumber,
				sellerName: data.seller
					? data.seller.name
					: data.associateName || "Unasigned seller",
				seller: data.seller ? data.seller._id : "",
				sellerPhone: data.seller ? data.seller.phone : "",
				name: data.name,
				scheduledDate: data.scheduledDate,
				// date: new Date(data.scheduledDate),
				scheduledTime: data.scheduledTime,
				loadYD3: data.loadYD3,
				targetYD3:
					typeof data.targetYD3 === "string"
						? parseFloat(data.targetYD3)
						: data.targetYD3,
				balance: data.balance,
				jobDescription: data.jobDescription,
				jobStatus: data.jobStatus,
				jobStatusPrev: data.jobStatus,
				customerId: data.customer._id,
				shipAddress: data.shipAddress,
				jobForeman: data.jobForeman,
				foremanPhone: data.foremanPhone,
				apContact: data.apContact,
				apEmail: data.apEmail,
				apPhone: data.apPhone,
				destinationMinutes: data.destinationMinutes,
				contractor: data.contractor,
				contractorContact: data.contractorContact,
				contractorPhone: data.contractorPhone,
				contractorAddress: data.contractorAddress,
				contractorCity: data.contractorCity,
				contractorState: data.contractorState,
				contractorZip: data.contractorZip,
				contractorType: data.contractorType,
				taxExemptJob: data.taxExemptJob,
				comments: data.comments,
				driver: data.driver,
				po: data.po,
				jobDuration: data.jobDuration,
				unitPrice: data.unitPrice,
				taxPrice: data.unitPrice * 1.0825,
				slump: data.slump,
				jobType: data.jobType,
				jobPoor: data.jobPoor,
				number: data.number,
				isPhoneQuote: data.isPhoneQuote,
				paid: data.paid,
				cliente: data.customer.customerNumber + " " + data.customer.name,
				psi: data.psi,
				destinationTime: data.destinationTime,
				distance: data.distance,
				latitude: data.latitude,
				longitude: data.longitude,
				paymentType: data.paymentType,
				approved: data.approved,
				hotShot: data.hotShot,
				supervisorPhone: data.supervisorPhone,
				pmPhone: data.pmPhone,
				supervisorName: data.supervisorName,
				projectManager: data.projectManager,
				fuelSurcharge: data.fuelSurcharge,
				shippingFee: data.deliveryFee,
				fuelSurchargeGlobal: data.fuelSurchargeGlobal,
				shippingGlobal: data.shippingGlobal,
				shipCity: data.shipCity,
				selectedOption: data.shipCity
					? { value: data.shipCity.toLowerCase(), label: data.shipCity }
					: {},
				plant: data.plant || "",
				logisticDate: data.logisticDate ? data.logisticDate : null,
				qualityControl: data.qualityControl,
				jobStatusHistory: data.jobStatusHistory,
				jobStatus2: this.manageJobStatus(data.jobStatus),
				fiberQuantity: data.fiberQuantity ? data.fiberQuantity : 0,
				inspectionTimeStamp: data.inspectionTimeStamp
					? new Date(data.inspectionTimeStamp)
					: new Date(),
				minDateScheduledDate: data.hotShot
					? new Date(data.scheduledDate)
					: new Date(new Date(data.scheduledDate).setHours(36)),
			});

			this.getDatePermissions(data.jobStatus);
			this.setState({ loading: false });
		} catch (err) {
			console.error(err);
			this.setState({ loading: false });
			return null;
		}
	};

	manageJobStatus = (status) => {
		if (status === "ok") {
			return "Poured";
		} else if (status === "Active") {
			return "Pouring";
		} else if (status === "Confirm") {
			return "A/R Confirmed";
		} else if (status === "Ready to Load") {
			return "Ready to be scheduled";
		} else {
			return status;
		}
	};

	deleteJob = async () => {
		this.setState({ loading: true });
		const url = api + "/job/" + this.props.match.params.id;
		const urlLoad = api + "/loadjob/" + this.props.match.params.id;
		const urlHistory = api + "/history";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const bodyHistory = {
			date: new Date(),
			customer: this.state.customerId,
			user: localStorage.getItem("userId"),
			type: "Job",
			yards: this.state.targetYD3,
			number: this.state.number,
		};

		const req1 = axios.delete(url);
		const req2 = axios.delete(urlLoad);
		const req3 = axios.post(urlHistory, bodyHistory);

		// llamada simultanea a los 2 borrados
		axios
			.all([req1, req2, req3])
			.then(
				axios.spread((...responses) => {
					const res1 = responses[0];
					const res2 = responses[1];
					const res3 = responses[2];
					this.setState({ loading: false });
					this.props.history.push("/dailyappointment");
				})
			)
			.catch((errors) => {
				this.setState({ loading: false });
				console.error(errors.message);
				return null;
			});
	};

	getLoadData = async () => {
		const url = api + "/load/" + this.props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			this.setState({
				loads: data,
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	jobPhotos = () => {
		const url = "/jobphotos/" + this.props.match.params.id;
		this.props.history.push(url);
	};

	jobInvoice = () => {
		const url = "/invoice/" + this.props.match.params.id;
		this.props.history.push(url);
	};

	showHistory = () => {
		const url = "/jobhistory/" + this.state.number;
		this.props.history.push(url);
	};

	showQuote = () => {
		const url = "/quotepdf/" + this.props.match.params.id;
		this.props.history.push(url);
	};

	showInvoice = () => {
		this.updateJob("invoice");
		const url = "/jobtoinvoice/" + this.props.match.params.id;
		this.props.history.push(url);
	};

	showEtickets = () => {
		const url = "/eticketsjob/" + this.props.match.params.id;
		this.props.history.push(url);
	};

	getDatePermissions = (status) => {
		if (
			localStorage.getItem("email") === "mv@57concrete.com" ||
			localStorage.getItem("email") === "vm@57concrete.com" ||
			localStorage.getItem("email") === "ecavazos@57concrete.com" ||
			localStorage.getItem("email") === "ga@57concrete.com" ||
			localStorage.getItem("email") === "9565660769@vendedor.com" ||
			localStorage.getItem("email") === "sdf@gmail.com" ||
			localStorage.getItem("email") === "jp@57concrete.com" ||
			localStorage.getItem("email") === "eliud@gmail.com"
		) {
			this.setState({
				disabledDate: false,
			});
		} else {
			if (
				status === "New Order" ||
				status === "Wait Inspection" ||
				status === "Waiting Inspection"
			) {
				this.setState({
					disabledDate: false,
				});
			} else {
				this.setState({
					disabledDate: true,
				});
			}
		}
	};

	changeStatusLabels = (jobStatus) => {
		if (jobStatus === "ok") {
			return "Poured";
		} else if (jobStatus === "Confirm") {
			return "A/R Confirmed";
		} else if (jobStatus === "Active") {
			return "Pouring";
		} else if (jobStatus === "Ready to Load") {
			return "Ready to be scheduled";
		} else {
			return jobStatus;
		}
	};

	JobForm = () => (
		<form className="form" onSubmit={this.onSubmit}>
			<label className="text-muted">Job ID</label>
			<div className="form-group">
				<input
					type="text"
					className="form-control"
					readOnly
					value={this.state.number}
				/>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Current Customer: </label>
				</div>
				<div className="col-sm-6">
					<strong>{this.state.cliente}</strong>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">New customer</label>
					<ClientPicker action={this.onChangeCustomer} />
				</div>
				<div className="col-sm-6">
					<label>{"Add new client\n"}</label>
					<button className="button btn-primary" onClick={this.onShowNewClient}>
						New Client +
					</button>
					<Modal
						show={this.state.clientModal}
						animation={false}
						onHide={this.onShowNewClient}
					>
						<Modal.Header closeButton>
							<Modal.Title>New Client</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ClientModal parentCallback={this.onNewClientNew} />
						</Modal.Body>
					</Modal>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Seller</label>
					<input
						type="text"
						name="seller"
						className="form-control"
						readOnly
						defaultValue={this.state.sellerName}
					/>
				</div>
				{(localStorage.getItem("role") === "Admin" ||
					localStorage.getItem("email") === "rl@57concrete.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com") && (
					<div className="col-sm-6">
						<label className="text-muted">New Seller</label>
						<SellerPicker action={this.onChangeSeller} />
					</div>
				)}
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Current Job Status </label>
					<input
						type="text"
						name="status"
						className="form-control"
						readOnly
						defaultValue={this.state.jobStatus2}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">New Job Status</label>
					<JobstatusPicker action={this.onChangeJobStatus} />
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Job name</label>
					<input
						type="text"
						name="name"
						className="form-control"
						onChange={this.inputChangeHandler}
						value={this.state.name ? this.state.name : ""}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Job Description</label>
					<input
						type="text"
						name="jobDescription"
						className="form-control"
						onChange={this.inputChangeHandler}
						value={this.state.jobDescription ? this.state.jobDescription : ""}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Job Type: {this.state.jobType} </label>
					<JobTypePicker
						action={this.onChangeJobType}
						placeholder={"Job Type..."}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Job Pour: {this.state.jobPoor} </label>
					<JobPoorPicker
						action={this.onChangeJobPoor}
						placeholder={"Job Pour..."}
					/>
				</div>
			</div>
			<div className="form-group">
				<label className="text-muted">PSI: {this.state.psi} </label>
				<PsiPicker action={this.onChangePsi} />
			</div>

			<div>
				<label className="text-muted">Ship to Address</label>
			</div>
			<div className="form-group row">
				<div className="col-sm-4">
					<button
						className="btn btn-primary"
						type="button"
						onClick={this.onShowModal}
						disabled={!this.state.plant}
					>
						Search address <FontAwesomeIcon icon={faSearch} />
					</button>
				</div>
				<div className="col-sm-8">
					<input
						type="text"
						name="shipAddress"
						className="form-control"
						value={this.state.shipAddress}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>

			{/* <div className="form-group row">
                <label className='text-muted'>Ship Address</label>
                <div className='col-sm-12'>
                    <input
                        type="text"
                        name='shipAddress'
                        className="form-control"
                        onChange={this.inputChangeHandler}
                        value={this.state.shipAddress}
                        readOnly
                    />
                </div>
                <div className='col-sm-4'>
                    <button 
                        className='btn btn-primary' 
                        onClick={this.onShowModal}
                    >
                        Change address <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>                                  
            </div> */}
			<div>
				{this.state.searchAddress && (
					<Modal
						show={this.state.searchAddress}
						animation={false}
						onHide={this.onShowModal}
					>
						<Modal.Header closeButton>
							<Modal.Title>Address</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddressGeolocation
								search={this.state.searchAddress}
								parentCallback={this.handleCallback}
								single={false}
								plant={this.state.plant}
							/>
						</Modal.Body>
					</Modal>
				)}
			</div>
			<br></br>
			<div className="form-row d-flex justify-content-end">
				<div className="col-sm-8">
					<Select
						options={cityList}
						onChange={this.setSelected}
						value={this.state.selectedOption}
						placeholder="City..."
					/>
				</div>
			</div>

			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Foreman</label>
					<ForemanPicker
						action={this.onChangeForeman}
						selectedOption={this.state.jobForeman}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Foreman Phone </label>
					<PhoneInput
						className="form-control"
						defaultCountry="US"
						country="US"
						placeholder="Foreman phone"
						value={this.state.foremanPhone}
						disabled
						// onChange={this.onChangeForemanPhone}
						// required
					/>
				</div>
			</div>
			{/* <div className='form-group row'>
                <div className='col-sm-6'>
                    <label className='text-muted'>Foreman</label>
                    <input
                        type='text'
                        name='jobForeman'
                        className='form-control'
                        placeholder='Job foreman'
                        value={this.state.jobForeman}
                        onChange={this.inputChangeHandler}
                    />                         
                </div>
                <div className='col-sm-6'>
                    <label className='text-muted'>Foreman Phone </label>   
                    <PhoneInput
                        className="form-control"
                        defaultCountry="US"
                        country="US"
                        placeholder="Foreman phone"
                        value={this.state.foremanPhone}
                        onChange={this.onChangeForemanPhone}
                        required
                    />
                </div>     
            </div> */}
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Supervisor</label>
					<input
						type="text"
						name="supervisorName"
						className="form-control"
						placeholder="Supervisor name"
						value={this.state.supervisorName}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Supervisor Phone</label>
					<PhoneInput
						className="form-control"
						defaultCountry="US"
						country="US"
						name="supervisorPhone"
						placeholder="Phone number"
						value={this.state.supervisorPhone}
						onChange={this.onChangeSupervisorPhone}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Project Manager</label>
					<input
						type="text"
						name="projectManager"
						className="form-control"
						placeholder="PM Name"
						value={this.state.projectManager}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Project Manager Phone</label>
					<PhoneInput
						className="form-control"
						defaultCountry="US"
						country="US"
						name="pmPhone"
						placeholder="PM Phone"
						value={this.state.pmPhone}
						onChange={this.onChangePMPhone}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">AP Contact</label>
					<input
						type="text"
						name="apContact"
						className="form-control"
						placeholder="Name"
						value={this.state.apContact}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted"> </label>
					<input
						type="text"
						name="apPhone"
						className="form-control"
						placeholder="Email"
						value={this.state.apPhone}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>

			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Tax Exempt Job</label>
					<div className="radio">
						<label>
							<input
								type="radio"
								name="taxExemptJob"
								value="Yes"
								onChange={this.inputChangeHandler}
							/>
							Yes
						</label>{" "}
						<label>
							<input
								type="radio"
								name="taxExemptJob"
								value="No"
								checked
								onChange={this.inputChangeHandler}
							/>
						</label>
						No
					</div>
				</div>
				{localStorage.getItem("role") === "Finance" && (
					<div className="col-sm-6">
						<label className="text-muted">
							Payment type: {this.state.paymentType}
						</label>
						<PaymentTypePicker action={this.onChangePaymentType} />
					</div>
				)}
			</div>
			<hr />
			<div className="form-group row">
				<div className="col-sm-4">
					<label className="text-muted">Target YD3</label>
					<input
						type="number"
						name="targetYD3"
						className="form-control"
						required
						onChange={this.inputChangeHandler}
						value={this.state.targetYD3}
					/>
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Balance</label>
					<input
						type="number"
						name="balance"
						className="form-control"
						onChange={this.inputChangeHandler}
						value={this.state.balance}
					/>
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Job Duration</label>
					<input
						type="text"
						name="jobDuration"
						className="form-control"
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<div>
						<label className="text-muted">
							Current Job Date:{" "}
							{new Date(this.state.scheduledDate).toLocaleDateString()}
							&nbsp; - Time:{" "}
							{new Date(this.state.scheduledDate).toLocaleTimeString()}
						</label>
					</div>
					<div>
						New date:
						<DatePicker
							selected={this.state.date}
							showTimeSelect
							timeFormat="HH:mm"
							dateFormat="MM/dd/yyyy h:mm aa"
							onChange={this.onChangeDate}
							disabled={this.state.disabledDate}
							minDate={this.state.minDateScheduledDate}
							minTime={
								this.state.date < this.state.minDateScheduledDate
									? new Date(
											0,
											0,
											0,
											this.state.minDateScheduledDate.getHours(),
											this.state.minDateScheduledDate.getMinutes()
									  )
									: new Date(0, 0, 0, 0, 0)
							}
							maxTime={new Date(0, 0, 0, 23, 59)}
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">
							Current Inspection Date:{" "}
							{new Date(this.state.inspectionTimeStamp).toLocaleDateString()}
						</label>
						<label className="text-muted">
							Time:{" "}
							{new Date(this.state.inspectionTimeStamp).toLocaleTimeString()}
						</label>
						<div>
							New Inspection:
							<DatePicker
								selected={this.state.inspectionTimeStamp}
								showTimeSelect
								timeFormat="HH:mm"
								dateFormat="MM/dd/yyyy h:mm aa"
								onChange={this.onChangeInspectionDate}
								maxDate={new Date(this.state.scheduledDate)}
								required
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Time to destination (min)</label>
					<input
						type="text"
						name="destinationMinutes"
						className="form-control"
						onChange={this.inputChangeHandler}
						value={this.state.destinationMinutes}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Fiber Quantity</label>
					<input
						type="number"
						name="fiberQuantity"
						className="form-control"
						onChange={this.inputChangeHandler}
						value={this.state.fiberQuantity}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Unit price</label>
					<input
						type="text"
						name="unitPrice"
						className="form-control"
						value={this.state.unitPrice}
						onChange={this.unitPriceInput}
						placeholder="$"
						required
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Price with Tax</label>
					<input
						type="text"
						name="taxPrice"
						readOnly
						className="form-control"
						value={toCurrency(this.state.taxPrice, 2)}
					/>
				</div>
			</div>
			{
				// condicion original-> this.state.isPhoneQuote
				localStorage.getItem("role") === "Finance" && (
					<div className="my-2">
						<input
							name="accept"
							type="checkbox"
							checked={this.state.paid}
							onChange={this.onPaidChange}
						/>
						<label>&nbsp;Paid</label>
					</div>
				)
			}
			<div className="form-group row">
				<div className="col-sm-2 d-flex justify-content-center">
					<input
						name="fuelSurchargeGlobal"
						type="checkbox"
						checked={this.state.fuelSurchargeGlobal}
						onChange={(e) =>
							this.setState({
								fuelSurchargeGlobal: !this.state.fuelSurchargeGlobal,
							})
						}
					/>
					<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
				</div>
				<div className="col-sm-10">
					<label className="text-muted">Fuel Surcharge</label>
					<input
						type="text"
						name="fuelSurcharge"
						className="form-control"
						value={this.state.fuelSurcharge}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-2 d-flex justify-content-center">
					<input
						name="shippingGlobal"
						type="checkbox"
						checked={this.state.shippingGlobal}
						onChange={(e) =>
							this.setState({
								shippingGlobal: !this.state.shippingGlobal,
							})
						}
					/>
					<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
				</div>
				<div className="col-sm-10">
					<label className="text-muted">ShippingFee</label>
					<input
						type="text"
						name="shippingFee"
						className="form-control"
						value={this.state.shippingFee}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group">
				<label className="text-muted">Comments</label>
				<input
					type="text"
					name="comments"
					className="form-control"
					onChange={this.inputChangeHandler}
					value={this.state.comments}
				/>
			</div>
			<div className="form-group">
				<label className="text-muted">Current Slump: {this.state.slump} </label>
				<SlumpPicker action={this.onChangeSlump} />
			</div>
			<div className="my-2">
				<input
					name="qualityControl"
					type="checkbox"
					checked={this.state.qualityControl}
					onChange={this.onQualityControlChange}
				/>
				<label>&nbsp;QC Inspection</label>
			</div>
			<div>
				<h3>Job Status History</h3>
				<table className="table">
					<thead>
						<th>Date</th>
						<th>User</th>
						<th>Status</th>
					</thead>
					<tbody>
						{this.state.jobStatusHistory.length > 0 ? (
							this.state.jobStatusHistory.map((item) => {
								return (
									<tr>
										<td>{`${new Date(
											item.createdAt
										).toLocaleDateString()} ${new Date(
											item.createdAt
										).toLocaleTimeString()}`}</td>
										<td>{item.user.name}</td>
										<td>{this.changeStatusLabels(item.jobStatus)}</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan={"3"} className="centered">
									No history registered
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="my-2">
				{/* <input className='btn btn-success' value='Save' type='submit' /> */}
				{this.state.loading ? (
					<SpinnerButton type={"success"} />
				) : (
					<button className="btn btn-success" type="submit">
						{" "}
						Save{" "}
					</button>
				)}
				&nbsp;
				{/* <input 
                    type="text"
                    readOnly
                    value="Create invoice"
                    className="btn btn-primary"
                    onClick={(e) => this.jobInvoice()}
                /> */}
				{
					// (localStorage.getItem('role') === 'Admin' ||
					//     localStorage.getItem('role') === 'Finance') &&
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ? (
						// <input
						//     type="text"
						//     className="btn btn-danger"
						//     readOnly
						//     value="Delete"
						//     onClick={(e) => {if (window.confirm('Are you sure you want to delete the job?')) this.deleteJob()}}
						// />
						// <button
						//     className='btn btn-danger'
						//     onClick={(e) => { if (window.confirm('Are you sure you want to delete the job?')) this.deleteJob() }}
						// >
						//     Delete
						// </button>

						this.state.loading ? (
							<SpinnerButton type={"danger"} />
						) : (
							<button
								className="btn btn-danger"
								type="button"
								onClick={(e) => {
									if (
										window.confirm("Are you sure you want to delete the job?")
									)
										this.deleteJob();
								}}
							>
								{" "}
								Delete{" "}
							</button>
						)
					) : (
						<span></span>
					)
				}
				&nbsp;
				{localStorage.getItem("role") === "Admin" ||
				localStorage.getItem("role") === "Seller" ||
				localStorage.getItem("email") === "eliud@gmail.com" ? (
					// <button
					//     className='btn btn-secondary'
					//     onClick={this.jobPhotos}
					// >
					//     Photos
					// </button>
					this.state.loading ? (
						<SpinnerButton type={"secondary"} />
					) : (
						<button
							className="btn btn-secondary"
							type="button"
							onClick={this.jobPhotos}
						>
							{" "}
							Photos{" "}
						</button>
					)
				) : (
					<span></span>
				)}
				&nbsp;
				{localStorage.getItem("role") === "Admin" ||
				localStorage.getItem("role") === "Finance" ? (
					// <button
					//     className='btn btn-warning'
					//     onClick={this.showHistory}
					// >
					//     Change Log
					// </button>
					this.state.loading ? (
						<SpinnerButton type={"warning"} />
					) : (
						<button
							className="btn btn-warning"
							type="button"
							onClick={this.showHistory}
						>
							{" "}
							Change Log{" "}
						</button>
					)
				) : (
					<span></span>
				)}
				&nbsp;
				{
					// <button
					//     className='btn btn-primary'
					//     onClick={this.showQuote}
					// >
					//     Quote PDF
					// </button>
					this.state.loading ? (
						<SpinnerButton type={"primary"} />
					) : (
						<button
							className="btn btn-primary"
							type="button"
							onClick={this.showQuote}
						>
							{" "}
							Quote PDF{" "}
						</button>
					)
				}
				&nbsp;
				{this.state.jobStatus === "Operations done (AR)" ? (
					// <button
					//     className='btn btn-dark'
					//     onClick={this.showInvoice}
					// >
					//     Invoice
					// </button>
					this.state.loading ? (
						<SpinnerButton type={"dark"} />
					) : (
						<button
							className="btn btn-dark"
							type="button"
							onClick={this.showInvoice}
						>
							{" "}
							Invoice{" "}
						</button>
					)
				) : (
					<span></span>
				)}
				&nbsp;
				{this.state.loads.length !== 0 ? (
					// <button
					//     className='btn btn-primary'
					//     onClick={this.showEtickets}
					// >
					//     Etickets
					// </button>
					this.state.loading ? (
						<SpinnerButton type={"primary"} />
					) : (
						<button
							className="btn btn-primary"
							type="button"
							onClick={this.showEtickets}
						>
							{" "}
							Etickets{" "}
						</button>
					)
				) : (
					<span></span>
				)}
			</div>
		</form>
	);

	componentDidMount() {
		this.getJob();
		this.getLoadData();
	}

	render() {
		return (
			<Layout
				title={"Job ID: " + this.state.number}
				description="View/Change job properties"
				className="container col-md-8 offset-md-2"
			>
				{this.JobForm()}
				{this.state.jobStatus === "Operations done (AR)" ? (
					<button
						onClick={() => this.onChangeShowLoad(true)}
						className="btn btn-secondary btn-block my-2"
						style={{ width: "455px" }}
					>
						Loads
					</button>
				) : (
					<button
						onClick={() => this.onChangeShowLoad(true)}
						className="btn btn-secondary btn-block my-2"
						style={{ width: "375px" }}
					>
						Loads
					</button>
				)}
				<ReadLoad
					handleModal={this.state.showLoad}
					close={() => this.onChangeShowLoad(false)}
					loads={this.state.loads}
					jobID={this.state.number}
					loadTotal={this.state.loadYD3}
				/>
			</Layout>
		);
	}
}
